import React, {useState,useEffect} from 'react';
import { Image,TouchableOpacity,FlatList,StyleSheet,Text,TextInput, View, Button } from 'react-native';
import { Auth } from 'aws-amplify';
import { DarkTheme } from './marketsDarkTheme';

import Theme from '../theme/theme';


const background = Theme.background
const header = Theme.header
const header_text_color = Theme.header_text_color
const font = Theme.font
const row_background_color = Theme.row_background_color
const other_text_color = Theme.other_text_color
const native_label_color = Theme.native_label_color
const border_color = Theme.border_color
const labels = Theme.labels
const chart_line = Theme.chart_line
const chart_labels = Theme.chart_labels

import Animated, {
    FadeIn,
    withTiming,
    useDerivedValue,
    useAnimatedStyle,
    interpolateColor,
    FadeOut,
  } from 'react-native-reanimated';

import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';

const getUser = async() => {
    var userData = await Auth.currentAuthenticatedUser();
    var email = userData.attributes.email;
    return email;
  }


function MarketsContentLoader({ navigation,route }) {
      const [marketData,setMarketData] = useState([{"id":1},{"id":2},{"id":3},{"id":4},{"id":5},{"id":6}]);
      const [node,setNode]= useState(0);

      async function loopAnim(){
        console.log(marketData[0]);
      }

      useEffect(() => {
        // Change the state every second or the time given by User.
        const interval = setInterval(() => {
          setNode((node) => !node);
        }, 1000);
        return () => clearInterval(interval);
      }, []);

      const progress = useDerivedValue(
        ()=> { 
          return node == 1 ? withTiming(0,{duration:500}) : withTiming(1,{duration:500})
        }
      );

      const backgroundStyle = useAnimatedStyle(() => {
        const backgroundColor = interpolateColor(
          progress.value,
          [0, 1],
          ['rgb(80, 87, 133)','rgb(80, 87, 100)'],
        );
      
        return {
          backgroundColor,
        };
      });

      const backgroundStyle1 = useAnimatedStyle(() => {
        const backgroundColor = interpolateColor(
          progress.value,
          [0, 1],
          ['#D8D8D8','#FFF'],
        );
      
        return {
          backgroundColor,
        };
      });
      

      
      return (
        <Animated.View entering={FadeIn.duration(400)}>
         
                <FlatList
                        style={{width: '100%'}}
                        data={marketData}
                        extraData={marketData}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        renderItem={({item}) => (
                            
                        <TouchableOpacity

                        onPress={() => {null}}>    
                        <Animated.View style={[styles.rowContainermain]}>
                            <View style={{ flexDirection:'row',alignItems:'center',width:'100%'}}>
                                
                            <Image 
                                    source={{uri:"https://token-images.s3.amazonaws.com/bnb-gray.png"}}
                                    style={DarkTheme.imgStylemain}
                                    resizeMode={'contain'}
                                    ></Image>
                                <Animated.View style={[styles.placeholder,backgroundStyle1]}></Animated.View>
                            </View>
                            <View style={{width:'100%',alignItems:'center',justifyContent:'center'}}>
                                
                                <Animated.View style={[styles.valuesmain,backgroundStyle1]}></Animated.View>
                            </View>
                            <View style={{flexDirection:'row',justifyContent:"space-evenly",width:'100%',alignItems:'center'}}>
                                
                            <Animated.View style={[styles.valuesmain,backgroundStyle1]}></Animated.View>
                                
                            </View>
                            
                            
                            

                        </Animated.View>
                        </TouchableOpacity> 
                        

                        ) }
                        
                        keyExtractor={(item)=> item.id}
                        />
                        </Animated.View>
        
      )              
  
    
                    
  }

export default MarketsContentLoader;

const styles = StyleSheet.create({
      test:{
        height:'100%'
      },
      rowContainermain: {
        flexDirection:'column',
      justifyContent:'space-evenly',
      backgroundColor: row_background_color,
      borderWidth: 2,
      borderRadius:10,
      borderColor:border_color,
      height: 150,
      width:150,
      marginVertical:3,
      marginHorizontal:3,
      paddingHorizontal:10,
      },
      namemain: {
        color:'white',
        fontFamily: 'Poppins_500Medium',
        fontSize:20,
        paddingLeft:5,
      },
      valuesmain: {
      
        color:'#fff',
        fontFamily: 'Poppins_500Medium',
        fontSize:15,
        
        width:90,
        height:10,
        borderRadius:10,
        


      },
      placeholder: {
        color:'#fff',
        fontFamily: 'Poppins_500Medium',
        fontSize:15,
        backgroundColor:'#D8D8D8',
        width:50,
        height:10,
        borderRadius:10,
        marginLeft:7
      },
    
      name: {
        color:'black',
        fontFamily: 'Poppins_500Medium',
        fontSize:20,
        paddingLeft:5,
      },
      contract: {
        color:'#000',
        fontFamily: 'Poppins_500Medium',
        fontSize:12,
        marginTop:0,
      },
      
      labels: {
        color:'#9c9c9c',
        fontFamily: 'Poppins_500Medium',
        fontSize:12,
      },
      values: {
        
        color:'#000',
        fontFamily: 'Poppins_500Medium',
        fontSize:15,
      },
      searchRowContainer: {
        alignSelf:'center',
        flexDirection:'row',
        justifyContent:'space-evenly',
        backgroundColor: '#fff',
        borderWidth: 2,
        borderRadius:10,
        borderColor:'#f6f6f6',
        height: 65,
        width:'97%',
        marginVertical:3,
        marginHorizontal:3,
        paddingHorizontal:10,
      },
      searchSubContainers: {
        alignItems:'center',
        justifyContent:'center',
        width:'33%',
        flexDirection:'row'
      },
      searchLabelsContainer: {
        alignSelf:'center',
        flexDirection:'row',
        justifyContent:'space-around',
        borderRadius:10,
        borderColor:'#f6f6f6',
        height: 20,
        width:'97%',
        marginVertical:3,
        marginHorizontal:3,
        paddingHorizontal:10,
      },
    
      imgStyle: {
        marginLeft:8,
        width: 40,
        height: 40,
      },
      
      textInput: {
        width:'70%',
        paddingLeft:14,
        fontFamily: 'Poppins_500Medium',
        fontSize:15,
        },

    rowPercentageGain: {
        fontFamily: 'Poppins_500Medium',
        marginLeft:0,
        paddingTop:0,
        
        paddingRight:0,
        color: '#699e71',
        
        fontSize:17,
        fontWeight:'bold',
    
    },
    rowPercentageLoss: {
        fontFamily: 'Poppins_500Medium',
        marginLeft:0,
        paddingTop:0,
        
        paddingRight:0,
        color: '#CF6679',
        
        fontSize:17,
        fontWeight:'bold',
    
    },
    

  });