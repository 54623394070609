import React, {useState,useEffect,componentDidMount} from 'react';
import {ActivityIndicator, Dimensions } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { ScrollView,Image,TouchableOpacity,TextInput,Text, View,Linking } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { LineChart } from "react-native-chart-kit";
import { Auth} from 'aws-amplify';
import { useFonts, Poppins_500Medium } from '@expo-google-fonts/poppins';
import { DarkTheme } from './portfolioDarkTheme';
import { LightTheme } from './portfolioLightTheme';
import AppLoading from 'expo-app-loading';
import Moment from 'moment';
import Menu from '../components/menu';




const getUser = async() => {
  var userData = await Auth.currentAuthenticatedUser();
  var email = userData.attributes.email;
  return email;
}

function Transactions({route,navigation}) {
    const [updatingTransaction,setUpdatingTransaction]=useState(undefined);
    const [manualPrice,setManualPrice]=useState(undefined);

    useEffect(() => {

      console.log(route.params.item._id)
    }, [route.params.item.contract])

    async function updateTransaction(){
        route.params.item.spent_total_manual = manualPrice;
        setUpdatingTransaction(true);
        var body = {"id":route.params.item.id,"manualPrice":manualPrice};
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };
        var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/transactions/edit?id="+route.params.item._id+"&amount="+manualPrice, requestOptions)
        var values = await grab.json();
        console.log(values);
        
        return values

    }



    const Images = {
      "bsc":require( './../img/logos/bnb.png'),
      "eth":require( './../img/logos/eth.png'),
      "poly":require( './../img/logos/poly.png')

    }

    const explorers = {
        "bsc":"https://bscscan.com/tx/"+route.params.item.hash,
        "eth":"https://etherscan.io/tx/"+route.params.item.hash,
        "poly":"https://polygonscan.com/tx/"+route.params.item.hash

      }


    const Header = () => (
      <View style={DarkTheme.headerBackground}>
              <TouchableOpacity style={{position:'absolute',paddingRight:300,paddingBottom:8,justifyContent:'center',alignItems:'center'}} onPress={() =>navigation.goBack()}>
                          <Text style={DarkTheme.values}><FontAwesome5 name={'angle-double-left'} style={{fontSize:25,color:'#fff'}} /></Text>
              </TouchableOpacity>
              <Text style={DarkTheme.headerText}>Transactions</Text>
          </View>
    )






    return (
      <View style={DarkTheme.container}>
        <Header />
        <Menu props={{"navigation": navigation}}/>


        <View style={{marginTop:20,marginBottom:10,flexDirection:'row',width:'90%'}}>
            <View style={{width:(route.params.item.chain == 'poly')? "31%":"41%"}}>
                <Text style={DarkTheme.transactionDetails}>Details</Text>
            </View>
            <View style={{width:(!updatingTransaction)?'40%':'25%',marginLeft:(!updatingTransaction)?'10%':'0%',paddingTop:5}}>
                {!updatingTransaction ?
                <TouchableOpacity  onPress={() =>Linking.openURL((route.params.item.chain == 56) ? explorers.bsc : (route.params.item.chain == 1) ? explorers.eth : explorers.poly)}>
                            <Text style={DarkTheme.values}><FontAwesome5 name={'wpexplorer'} style={{fontSize:18}} />  {(route.params.item.chain == 56) ? "Bsc Scan" : (route.params.item.chain == 1) ? 'Ether Scan' : "Polygon Scan"}</Text>
                </TouchableOpacity>
                :
                <TouchableOpacity style={{flexDirection:'row',
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:'#699e71',
                paddingVertical:7,
                marginTop:-7,
                width:'100%',
                borderColor:'#699e71',
                borderWidth:1,
                borderRadius:150}} onPress={() =>{updateTransaction(); route.params.item.manual_spent_total = manualPrice; setUpdatingTransaction(false)}}>
                             <FontAwesome5 name={'check-circle'} style={{fontSize:20,color:"#fff"}} /><Text style={DarkTheme.values}>  Save</Text>
                </TouchableOpacity>
                }
            </View>
            <View style={{width:(!updatingTransaction)?'10%':'30%',paddingTop:5}}>
                {!updatingTransaction ?
                <TouchableOpacity  onPress={() =>updatingTransaction ? setUpdatingTransaction(false) : setUpdatingTransaction(true)}>
                            <FontAwesome5 name={'edit'} style={{fontSize:18,color:"#fff"}} />
                </TouchableOpacity>
                :
                <TouchableOpacity  style={{flexDirection:'row',
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:'#CF6679',
                paddingVertical:7,
                marginLeft:12,
                marginTop:-7,
                width:'100%',
                borderColor:'#CF6679',
                borderWidth:1,
                borderRadius:150}} onPress={() =>updatingTransaction ? setUpdatingTransaction(false) : setUpdatingTransaction(true)}>
                             <FontAwesome5 name={'times-circle'} style={{fontSize:20,color:"#fff"}} /><Text style={DarkTheme.values}>  Cancel</Text>
                </TouchableOpacity>
            }
            </View>


        </View >
        <View style={DarkTheme.transactionsDataContainer}>
            <View style={{marginTop:17,flexDirection:'row',width:'90%',alignItems:'center'}}>
                <View style={{width:'20%'}}>
                    <Text style={DarkTheme.transactionLabels}>Date:</Text>
                </View>
                <View style={{width:'80%'}}>
                    <Text style={DarkTheme.transactionValues}>{Moment(route.params.item.date).format('MMMM D, YYYY')}</Text>
                </View>

            </View>




            <View style={{marginTop:17,flexDirection:'row',width:'90%',alignItems:'center'}}>
                <View style={{width:'20%'}}>
                    <Text style={DarkTheme.transactionLabels}>Hash:</Text>
                </View>
                <View style={{width:'80%'}}>
                <TouchableOpacity onPress={() => {Clipboard.setString(route.params.item.hash)}}>
                    <Text style={DarkTheme.transactionValues}>{route.params.item.hash.slice(0,6)+'...'+route.params.item.hash.slice(58)}  <FontAwesome5 name={'copy'} style={{fontSize:15}}/></Text>
                </TouchableOpacity>
                </View>

            </View>

            <View style={{marginTop:17,flexDirection:'row',width:'90%',alignItems:'center'}}>
                <View style={{width:'20%'}}>
                    <Text style={DarkTheme.transactionLabels}>Paid:</Text>
                </View>

                <View style={{width:'80%'}}>
                    <Text style={DarkTheme.transactionValues}>{parseFloat(route.params.item.spent_value).toFixed(4)} {route.params.item.spent_symbol} ({route.params.currency.currency_prefix} {(parseFloat(route.params.item.spent_total)*parseFloat(route.params.currency.currency_rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</Text>
                </View>

            </View>
            {route.params.item.spent_total_manual || route.params.item.spent_total_manual > 0 ?
            <View style={{marginTop:17,flexDirection:'row',width:'90%',alignItems:'center'}}>
                <View style={{width:'20%'}}>
                    <Text style={DarkTheme.transactionLabels}>Paid:</Text>
                </View>

                <View style={{width:'80%'}}>
                    <Text style={DarkTheme.transactionValues}>{route.params.currency.currency_prefix} {parseFloat(route.params.item.spent_total_manual).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (Manual)</Text>
                </View>

            </View>
            : null}
            {updatingTransaction ?
            <View style={{marginTop:17,flexDirection:'row',width:'90%',alignItems:'center'}}>
                <View style={{width:'30%'}}>
                    <Text style={DarkTheme.transactionLabels}>Amount Paid:</Text>
                </View>

                <View style={{width:'70%'}}>
                    <TextInput
                            keyboardType='numeric'
                            style={DarkTheme.textInputTransactions}
                            placeholder={(!route.params.item.manual_spent_total)?"250.43":parseFloat(route.params.item.manual_spent_total).toFixed(2)}
                            onChangeText={amount => setManualPrice(amount)}
                            />
                </View>
            </View>
            : null}
            <View style={{marginTop:17,flexDirection:'row',width:'90%',alignItems:'center'}}>
                <View style={{width:'20%'}}>
                    <Text style={DarkTheme.transactionLabels}>For:</Text>
                </View>
                <View style={{width:'80%'}}>
                    <Text style={DarkTheme.transactionValues}>{parseFloat(route.params.item.received_value).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {route.params.item.received_symbol}</Text>
                </View>
            </View>

            <View style={{marginTop:17,flexDirection:'row',width:'90%',alignItems:'center',borderBottomWidth:2,borderBottomColor:'#fff',paddingBottom:20}}>
                <View style={{width:'20%'}}>
                    <Text style={DarkTheme.transactionLabels}>Gas:</Text>
                </View>
                <View style={{width:'80%'}}>
                    <Text style={DarkTheme.transactionValues}>{parseFloat(route.params.item.transaction_fee).toFixed(3)} {(route.params.item.chain==56)?'BNB': (route.params.item.chain == 1) ? 'ETH' : "MATIC"} ({route.params.currency.currency_prefix} {(route.params.item.transaction_cost*parseFloat(route.params.currency.currency_rate)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</Text>
                </View>
            </View>

            <View style={{marginTop:17,flexDirection:'row',width:'90%',alignItems:'center'}}>
                <View style={{width:'20%'}}>
                    <Text style={DarkTheme.transactionLabels}>Total:</Text>
                </View>
                <View style={{width:'80%'}}>
                    {!route.params.item.spent_total_manual ?
                    <Text style={DarkTheme.transactionValues}>{route.params.currency.currency_prefix} {(((parseFloat(route.params.item.spent_total) + parseFloat(route.params.item.transaction_cost)))*parseFloat(route.params.currency.currency_rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                    :
                    <Text style={DarkTheme.transactionValues}>{route.params.currency.currency_prefix} {((((parseFloat(route.params.item.spent_total) + parseFloat(route.params.item.transaction_cost)))*parseFloat(route.params.currency.currency_rate))+parseFloat(route.params.item.spent_total_manual)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                    }
                </View>
            </View>

            <View style={{marginTop:17,marginBottom:20,flexDirection:'row',width:'90%',alignItems:'center'}}>
                <View style={{width:'20%'}}>
                    <Text style={DarkTheme.transactionLabels}>Price:</Text>
                </View>
                <View style={{width:'80%'}}>
                    <Text style={DarkTheme.transactionValues}>{route.params.currency.currency_prefix} {(parseFloat(route.params.item.received_price)*parseFloat(route.params.currency.currency_rate)).toFixed(12)} / Token</Text>
                </View>
            </View>

        </View>










      </View>


    );
  }

  export default Transactions;
