import React, {useState,useEffect} from 'react';
import {TouchableOpacity,FlatList,StyleSheet,Text,View,Image} from 'react-native';
import {Swipeable,GestureHandlerRootView } from 'react-native-gesture-handler';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useIsFocused } from "@react-navigation/native";
import Amplify, { Auth} from 'aws-amplify';
import * as Clipboard from 'expo-clipboard';
import { LightTheme } from './settingsLightTheme';
import { DarkTheme } from './settingsDarkTheme';
import Menu from '../components/menu';


import Theme from '../theme/theme';




const getUser = async() => {
    var userData = await Auth.currentAuthenticatedUser();
    var email = userData.attributes.email;
    return email;
  }



function HiddenTokens({ navigation }) {

    const [wallets,setWallets] = useState([]);
    const [selection,setSelection] = useState('hidden');
    const [edit,setEdit]=useState(false);


    async function getTokens(){
      try{
      const user = await getUser();


        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        const grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/holdings/all?email="+user, requestOptions);
        
        var data = await grab.json();
        console.log(data);
        data = data.filter(token => token.hidden == true).map(filteredTokens =>(filteredTokens))
           setWallets(data);

      } catch (e) {
        console.log(e)
      }


           
        };
    

    useEffect(() => {

        getTokens();
        }, [])



        function formatWallet(wallet) {
            if (wallet != null){
            wallet = wallet.slice(0,5)+'...'+wallet.slice(38);


            return wallet;
            }
          }


          async function saveToken(item,contract) {
            const fData = wallets.filter(item => item.contract !== contract);
            setWallets(fData);
            var user = await getUser();

            var myHeaders = new Headers();
            myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow'
            };
            fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/token-visibility?visible=false&email="+user+"&tokenId="+item._id, requestOptions).then(response => response.json())
            .then((responseJson) => { return responseJson
            });



          }

          const Header = () => (
            <View style={DarkTheme.headerBackgroundHiddenTokens}>
                <TouchableOpacity style={{position:'absolute',paddingRight:300,paddingBottom:8,justifyContent:'center',alignItems:'center'}} onPress={() =>navigation.goBack()}>
                           <FontAwesome5 name={'angle-double-left'} style={{fontSize:25,color:Theme.icon_color}} />
                </TouchableOpacity>
                    <Text style={DarkTheme.headerTextHiddenTokens}>Hidden Tokens</Text>
                </View>
          )

    return (
      <View style={DarkTheme.containerHiddenTokens}>
        <Header />
        <Menu props={{"navigation": navigation}}/>
        <View style={{justifyContent:'center',
                      alignItems:'center',
                      flexDirection:'row',
                      width: '75%',}}>
          <View style={{width:'95%',height:30,marginVertical:10,flexDirection:'row',alignItems:'center',justifyContent:'center',}}>
              <FontAwesome5 name={'arrow-left'} style={DarkTheme.swipeLeftIcon}/>
              <Text style={DarkTheme.labels}>  Swipe left or click the edit button to add a hidden token to your portfolio</Text>
          </View>
          <View style={{alignItems:'center',width:'5%',marginRight:"0%",}}>
                  <TouchableOpacity style={{width:'100%'}} onPress={()=> {!edit ?setEdit(true):setEdit(false);}}>
                  {edit ? <FontAwesome5 name={'times'} style={{fontSize:25,color:Theme.icon_color}}/> : <FontAwesome5 name={'edit'} style={{fontSize:25,color:Theme.icon_color}}/>}
                  </TouchableOpacity>
          </View>

        </View>
        
        <FlatList
        style={{width: '100%',}}
        data={wallets}
        renderItem={({item}) => (
          <GestureHandlerRootView>

            <Swipeable renderRightActions={(progress,dragX) => {
              const trans = dragX.interpolate({
                inputRange: [-100, 0],
                outputRange: [1, 0],
                extrapolate: 'clamp',
              });



              return (
                <>

                <TouchableOpacity style={DarkTheme.swipeStyle} onPress={()=> {saveToken(item,item.contract)}}>
                  <FontAwesome5 name={'plus'} style={{color:Theme.button_color,fontSize:'25px'}}/>
                </TouchableOpacity> 


                </>
              )
            }}>






          <View style={DarkTheme.rowContainerHiddenTokens}>
            <View style = {{justifyContent:'center', alignItems:'center',width:'25%'}}>

              <Image
                    source={{uri: item.url}}
                    style={DarkTheme.imgStyle}
                    resizeMode={'contain'}
                    ></Image>
              </View>
            <View style = {{justifyContent:'center', alignItems:'center'}}>
                <Text style={DarkTheme.labels}>Token Name</Text>
                <Text style={DarkTheme.wallets}>{item.name}</Text>

            </View>
            <View style = {!edit ? {justifyContent:'center', alignItems:'center',marginRight:'10%'}:{justifyContent:'center', alignItems:'center',marginRight:'0%'}}>
              <TouchableOpacity style={{justifyContent:'center', alignItems:'center',}}onPress={() => {Clipboard.setString(item.contract)}}>
                <Text style={DarkTheme.labels}>Contract</Text>
                <Text style={DarkTheme.walletName}>{formatWallet(item.contract)} <FontAwesome5 name={'copy'} style={{fontSize:14}}/></Text>

              </TouchableOpacity>

            </View>
            {edit ?
            <TouchableOpacity style={DarkTheme.swipeStyle} onPress={()=> {saveToken(item,item.contract)}}>
              <FontAwesome5 name={'plus'} style={{color:Theme.button_color,fontSize:'25px'}}/>
            </TouchableOpacity> : null}

          </View>

          </Swipeable>
            </GestureHandlerRootView>
          ) }
        extraData={wallets}
        keyExtractor={(item)=> item._id}
        />



      </View>
    );
  }

  export default HiddenTokens;
