import React, {useState,useEffect} from 'react';
import { ActivityIndicator, TouchableOpacity,FlatList,StyleSheet,Text,TextInput, View, Button } from 'react-native';
import Amplify, { Auth, withSSRContext } from 'aws-amplify';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFonts, Poppins_500Medium } from '@expo-google-fonts/poppins';
import AppLoading from 'expo-app-loading';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Menu from '../components/menu';

const getUser = async() => {
    var userData = await Auth.currentAuthenticatedUser();
    var email = userData.attributes.email;
    return email;
  }

  



const prefixes = [["$"],["€"],["£"],["¥"],["Lek"],["؋"],["ƒ"],["₼"],["Br"],["BZ$"],["$b"],["KM"],["P"],["лв"],["R$"],["៛"],["₡"],["kn"],["₱"],["Kč"],["kr"],["RD$"],["¢"],["Q"],["L"],["Ft"],["Rp"],["﷼"],["₪"],["J$"],["₩"],["₭"],["ден"],["RM"],["₨"],["₮"],["MT"],["C$"],["₦"],["B/."],["Gs"],["S/."],["zł"],["lei"],["Дин."],["S"],["R"],["CHF"],["NT$"],["฿"],["TT$"],["₴"],["$U"],["Bs"],["₫"],["Z$"]]


function Currency({ navigation,route }) {
    const [searching,setSearching]=useState(undefined);
    const [prefixSearching,setPrefixSearching]=useState(undefined);
    const [keyword,setKeyword] = useState("USD");
    const [currencies,setCurrencies] = useState(undefined);
    const [prefix,setPrefix] = useState('$');
    const [conversionRate,setConversionRate] = useState(undefined);
    const [button,setButton] = useState(false);


    useEffect(() => {
        getConverstionRate();
        setData();
        
      }, [navigation])

    async function getConverstionRate() {
        console.log(keyword);
        var data = await {"action":"all"};
        var raw = await JSON.stringify(data);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        var grab = await fetch("https://3ekla1k0v6.execute-api.us-east-1.amazonaws.com/beta/getCurrencyPrice", requestOptions)
        var newData = await grab.json();
        //console.log(newData);
        setCurrencies(newData);
        
      }

    const setData = async () => {
        var data = await getData();
        
        console.log(data);
        setKeyword(data.currency_code);
        setPrefix(data.currency_prefix);
        setConversionRate(data.currency_rate);
    }

    const storeData = async (value) => {
        try {
          const jsonValue = JSON.stringify(value)
          await AsyncStorage.setItem('@currency', jsonValue)
        } catch (e) {
          // saving error
        }
      }
    const getData = async () => {
        try {
          const jsonValue = await AsyncStorage.getItem('@currency');
          return jsonValue != null ? JSON.parse(jsonValue) : null;
        } catch(e) {
          // error reading value
        }
      }

      function Header() {
        return(
            <View style={localStyles.headerBackground}>
                <TouchableOpacity style={{position:'absolute',paddingRight:300,paddingBottom:8,justifyContent:'center',alignItems:'center'}} onPress={() =>navigation.goBack()}>
                       <FontAwesome5 name={'angle-double-left'} style={{fontSize:25,color:'#fff'}} />
                </TouchableOpacity>
                <Text style={localStyles.headerText}>Currency Settings</Text>
            </View>
            )
        
    
      }
    
    const Dropdown = () => (
        
        <View style={{width:135,justifyContent:'center',alignSelf:'flex-end',marginRight:26,marginTop:-20}}>
        <FlatList
                    style={{width: '100%',marginTop:10,marginBottom:"130%"}}
                    data={currencies}
                    renderItem={({item}) => (
                        
                    <TouchableOpacity 
    
                    onPress={async() => {setKeyword(item.currency_code);setConversionRate(item.currency_rate);setSearching(false);setButton(true)}}>    
                        <View style={localStyles.rowContainer}>
                            <View style={{width:'90%'}}>
                            <Text style={localStyles.rowSymbol}>{item.currency_code}</Text>
                            </View>
                            
                            
                            
    
                        </View>
                    </TouchableOpacity> 
                        
    
                        ) }
                    keyExtractor={(item)=> item.currency_code}
                    />
        </View>
        
    )

    const PrefixDropdown = () => (
        
        <View style={{width:135,justifyContent:'center',alignSelf:'flex-end',marginRight:26,marginTop:-20}}>
        <FlatList
                    style={{width: '100%',marginTop:10,marginBottom:"130%"}}
                    data={prefixes}
                    renderItem={({item}) => (
                        
                    <TouchableOpacity 
    
                    onPress={() => {setPrefix(item[0]);setPrefixSearching(false);setButton(true)}}>    
                        <View style={localStyles.rowContainer}>
                            <View style={{width:'90%'}}>
                            <Text style={localStyles.rowSymbol}>{item}</Text>
                            </View>
                        </View>
                    </TouchableOpacity> 
                        
    
                        ) }
                    
                    keyExtractor={(item)=> item}
                    />
        </View>
        
    )
    
    return (
        <View style={localStyles.container}>
            <Header />
            <Menu props={{"navigation": navigation}}/>
                <View style={{flexDirection:'row',marginVertical:15}}>
                    <View style={{width:'50%',justifyContent:'center',alignItems:'center'}}>
                        <Text style={localStyles.labels}>Currency Code</Text>
                    </View>
                    <View style={{width:'30%',justifyContent:'center',alignItems:'center'}}>
                        <TouchableOpacity style={{flexDirection:'row'}} onPress={()=> searching ? setSearching(false):setSearching(true)}>
                            <View style={localStyles.textContainer}>
                                
                                    <Text style={localStyles.textInput}>{keyword}</Text>
                                    
                                    <FontAwesome5 name={'angle-down'} style={{fontSize:25,color:'#fff'}} />
                                
                                
                            </View>
                        </TouchableOpacity>

                        
                        
                    </View>
                </View>
                {searching ? <Dropdown /> : null}


                <View style={{flexDirection:'row',marginVertical:15}}>
                    <View style={{width:'50%',justifyContent:'center',alignItems:'center'}}>
                        <Text style={localStyles.labels}>Currency Prefix</Text>
                    </View>
                    <View style={{width:'30%',justifyContent:'center',alignItems:'center'}}>
                        <TouchableOpacity style={{flexDirection:'row'}} onPress={()=> prefixSearching ? setPrefixSearching(false):setPrefixSearching(true)}>
                            <View style={localStyles.textContainer}>
                                
                                    <Text style={localStyles.textInput}>{prefix}</Text>
                                    
                                    <FontAwesome5 name={'angle-down'} style={{fontSize:25,color:'#fff'}} />
                                
                                
                            </View>
                        </TouchableOpacity>

                        
                        
                    </View>


                    
                
                </View>
                {prefixSearching ? <PrefixDropdown /> : null}
            <View>
                <TouchableOpacity 
                    style={button ? localStyles.saveButton : localStyles.saveButtonDisabled} 
                    onPress={async()=>{{ storeData({"currency_code":keyword,"currency_rate":conversionRate,"currency_prefix":prefix});setButton(false); }}}
                    disabled={button ? false : true}>
                    <Text style={button ? localStyles.buttonText : localStyles.buttonDisabled}>Update</Text>
                </TouchableOpacity>
            </View>
                
        </View>
    )
  }

export default Currency;

const localStyles = StyleSheet.create({
    container: {
        flex:2,
        backgroundColor: '#121212',
        alignItems: 'center',
        height:'100%'
        
      },
      headerBackground: {
        backgroundColor: '#505785',
        alignItems: 'center',
        justifyContent:'flex-end',
        height:90,
        width:'100%',},
    
    headerText: {
        fontFamily: 'Poppins_500Medium',
        color:'#fff',
        fontSize:25,
        paddingBottom:5,
    },
    rowContainer: {
        flexDirection:'row',
        alignItems:'center',
        backgroundColor: '#1F1B24',
        borderWidth: 2,
        borderRadius:10,
        borderColor:'#1F1B24',
        height: 50,
        marginVertical:3,
        marginHorizontal:12,
        paddingHorizontal:10,
        },
    rowSymbol: {
        fontFamily: 'Poppins_500Medium',
        
        color: '#fff',
        fontSize:20,
        fontWeight:'bold',
    },
    

    textInput: {
        width:'60%',
        fontFamily: 'Poppins_500Medium',
        fontSize:20,
        color:'#fff',
        
        },
    textContainer: {
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        width:'100%',
        height:50,
        backgroundColor:'#1F1B24',
        borderRadius:10
        },
    labels: {
        color:'#505785',
        fontFamily: 'Poppins_500Medium',
        fontSize:20,
        },
    saveButton: {
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor: '#505785',
        width:150,
        height:50,
        borderRadius:10,
        marginVertical:20
    },
    saveButtonDisabled: {
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor: 'rgba(80, 87, 133,0.3)',
        width:150,
        height:50,
        borderRadius:10,
        marginVertical:20
    },
    buttonText: {
        fontFamily: 'Poppins_500Medium',
        color: '#fff',
        fontSize:20,
        fontWeight:'bold',
    },
    buttonDisabled: {
        fontFamily: 'Poppins_500Medium',
        color: 'rgba(128,128,128,1)',
        fontSize:20,
        fontWeight:'bold',
    },
    

  });