import { StyleSheet} from 'react-native';


import Theme from '../theme/theme';


const background = Theme.background
const header = Theme.header
const header_text_color = Theme.header_text_color
const font = Theme.font
const row_background_color = Theme.row_background_color
const other_text_color = Theme.other_text_color
const native_label_color = Theme.native_label_color
const border_color = Theme.border_color
const labels = Theme.labels
const chart_line = Theme.chart_line
const chart_labels = Theme.chart_labels


const DarkTheme = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: background,
      alignItems: 'center',
      justifyContent: 'center',
      width:'100%',
      maxWidth:972,
      alignSelf:'center'
      
    },
    headerBackground: {
        
      backgroundColor: header,
      alignItems: 'center',
      justifyContent:'flex-end',
      height:90,
      width:'100%',},
  
    headerText: {
        fontFamily: font,
        color:other_text_color,
        fontSize:25,
        paddingBottom:5,
      },
    addButton: {
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:border_color,
      paddingVertical:7,
      marginRight:15,
      width:110,
      borderColor:border_color,
      borderWidth:1,
      borderRadius:150
    },
    addButtonText: {
      fontFamily: font,
      paddingRight:0,
      color: other_text_color,
      fontSize:13,
      fontWeight:'bold',
    },
    rowContainer: {
      flexDirection:'row',
      backgroundColor: row_background_color,
      borderRadius:10,
      height: 65,
      marginVertical:3,
      marginHorizontal:12,
      paddingHorizontal:10,
      borderWidth:2,
      borderColor:border_color
    },
  
    swipeStyle: {
      justifyContent:'center',
      alignItems:'center',
      marginVertical:5,
      marginLeft:15,
      width:'5%',
      height:55,
      backgroundColor: row_background_color,
      borderRadius:10,
    },

    hideIcon: {
      fontFamily: font,
      fontSize:20,
      color:other_text_color,
    },
    
    walletBorder: {
      width: '100%',
      
    },

   wallets: {
    fontFamily: font,
      paddingRight:0,
      color: other_text_color,
      fontSize:15,
      fontWeight:'bold',
   },
   walletName: {
      fontFamily: font,
      paddingRight:0,
      color: other_text_color,
      fontSize:15,
      fontWeight:'bold',
   },
   labels: {
    color:labels,
    fontFamily: font,
    fontSize:12,
  },
   addWalletContainer: {
    flexDirection:'row',
    justifyContent: 'center',
    alignItems:'center',
    backgroundColor:row_background_color,
    height:50,
    width:'100%'
    
  },
    addWalletSubContainer: {
        
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'row',
        height:'100%',
        width: '75%',
        
    },
    swipeLeftIcon: {
      fontSize:15,
      color:header
    },
    addWalletSubContainer1: {
      flexDirection:"row",
      justifyContent:'center',
      alignItems:'center',
      marginRight:30,
      height:'100%',
      width: '25%',
      
  },
    addIcon: {
        fontSize:15,
        color:Theme.button_color,},

    walletsAdd: {
      fontFamily: font,
      marginLeft:245,
      paddingTop:10,
      
      paddingRight:30,
      color: '#000',
      
      fontSize:15,
      fontWeight:'bold',
    },

    //ADD Wallet Screen
    
    containeraddWallet: {
        flex: 1,
        backgroundColor: background,
        alignItems: 'center',
        width:'100%',
        maxWidth:972,
        alignSelf:'center'
           
       },

    headerBackgroundaddWallet: {
          
        backgroundColor: header,
        alignItems: 'center',
        justifyContent:'flex-end',
        height:90,
        width:'100%',},
      
     headerTextaddWallet: {
        fontFamily: font,
        color:other_text_color,
        fontSize:25,
        paddingBottom:5,
       },

      form: {
          alignItems:'center',
          padding:10,
          marginTop:15
      
  
      },
      t: {
          fontFamily: font,
          color:other_text_color,
          fontSize:12,
          marginBottom: 15,
          paddingHorizontal:12,
      },
      button: {
          flexDirection: "row",
          justifyContent: "space-evenly",
          marginTop:15,
          alignItems: "center",
          backgroundColor: Theme.button_color,
          
          color:other_text_color,
          width: 200,
          height: 50,
          fontFamily: font,
          alignSelf:'center',
          borderRadius:10,
      },
      title: {
          fontFamily: font,
          color:other_text_color,
          fontSize:20,
          marginBottom: 25,
      },
      label: {
          fontFamily: font,
          color:labels,
          fontSize:15,
          marginBottom: 10,
      },
  
      textInput: {
          width:300,
          height:50,
          paddingHorizontal:10,
          marginBottom:25,
          backgroundColor:row_background_color,
          fontFamily: font,
          fontSize:15,
          borderRadius:10,
          },
  
      
      invalid: {
          marginTop:-15,
          marginBottom:10,
          paddingLeft:0,
          color:'#CF6679',
          fontFamily: font,
          fontSize:15,
          
          
          },
  
      buttonText: {
          fontFamily: font,
          color:other_text_color,
          fontSize:15,
          
      },
      steps: {
        fontFamily: font,
        color:header,
        fontSize:17,
        textAlign:'left'
      },
      stepsComplete: {
        fontFamily: font,
        color:other_text_color,
        fontSize:17,
        textAlign:'left'
      },
      stepColumn: {
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        marginVertical:7,
        width:'100%'
      },
      checks: {
        fontSize:25,
        color:header
      },
      checksComplete: {
        fontSize:25,
        color:other_text_color
      }
      
  
    });


const ScanWalletStyles = StyleSheet.create({
      container: {
          flex: 1,
          backgroundColor: background,
          alignItems: 'center',
          justifyContent:'center',
  
  
        },
      saveButton:{
        flexDirection:'row',
        marginVertical:20,
        backgroundColor:labels,
        borderRadius:10,
        paddingVertical:5,
        paddingHorizontal:50,
  
      },
  
      saveButtonText: {
        fontFamily: font,
        color:other_text_color,
        fontSize:25,
        paddingBottom:5,
      },
        headerBackground: {
          backgroundColor: header,
          alignItems: 'center',
          justifyContent:'flex-end',
          height:90,
          width:'100%',
        },
        headerText: {
            fontFamily: font,
            color:other_text_color,
            fontSize:25,
            paddingBottom:5,
          },
        warning:{
          color:other_text_color,
          alignSelf:'center',
          fontFamily:font,
          fontSize:13,
          paddingVertical:10,
          paddingHorizontal:15
        },
        rowContainer: {
          flexDirection:'row',
          backgroundColor: row_background_color,
          borderRadius:10,
          borderColor:border_color,
          height: 65,
          marginVertical:3,
          marginHorizontal:12,
          paddingHorizontal:10,
        },
        rowContainerSelected: {
          flexDirection:'row',
          backgroundColor: Theme.button_color,
          borderColor: '#FFF',
          borderWidth:2,
          borderRadius:10,
  
          height: 65,
          marginVertical:3,
          marginHorizontal:12,
          paddingHorizontal:10,
        },
      form: {
  
          padding:10,
  
      },
      button: {
          marginTop:15,
          alignItems: "center",
          backgroundColor: "white",
          paddingTop: 15,
          color:'white',
          width: 200,
          height: 50,
          fontFamily: font,
          alignSelf:'center',
  
      },
      title: {
          fontFamily: font,
          color:'white',
          fontSize:20,
  
          marginBottom: 25,
      },
      label: {
          fontFamily: font,
          color:labels,
          fontSize:15,
  
          marginBottom: 10,
      },
      selectionText: {
        fontFamily: font,
        color:'white',
        fontSize:13,
  
  
    },
  
      textInput: {
          width:300,
          height:50,
          paddingHorizontal:10,
          marginBottom:25,
          backgroundColor:'white',
          fontFamily: font,
  
  
  
          fontSize:15,
  
  
  
          },
  
          imgStyle: {
            width: 40,
            height: 40,
            marginTop:5,
  
          },
          wallets: {
            fontFamily: font,
              paddingRight:0,
              color: other_text_color,
              fontSize:15,
              fontWeight:'bold',
           },
           walletName: {
            fontFamily: font,
              paddingRight:0,
              color: other_text_color,
              fontSize:12,
              fontWeight:'bold',
           },
           loadingText: {
            fontFamily: font,
              paddingTop:10,
              color: '#000',
              fontSize:15,
              fontWeight:'bold',
           },
           labels: {
            color:labels,
            fontFamily: font,
            fontSize:12,
          },
          selectedlabels: {
           color:'#000',
           fontFamily: font,
           fontSize:12,
         },
         main: {
          height:'100%',
          backgroundColor:background,
          width:'100%',
          maxWidth:972,
          alignSelf:'center'
        },
        maincontainer: {
          width:'95%',
          backgroundColor:row_background_color,
          alignSelf:'center',
          marginVertical:'3%',
          paddingVertical:'3%',
          borderRadius:10,
          borderColor:border_color,
          borderWidth:2
        },
        mainwallet: {
          color:other_text_color,
          alignSelf:'center',
          fontFamily:font,
          fontSize:18,
          paddingTop:10,
          paddingHorizontal:10
        },
        mainlabel: {
          color:labels,
          alignSelf:'center',
          fontFamily:font,
          fontSize:14,
          paddingTop:10,
          paddingHorizontal:10
        },
        maindata: {
          color:other_text_color,
          alignSelf:'center',
          fontFamily:font,
          fontSize:18,
          paddingTop:0,
          paddingHorizontal:10
        }
  
  
    });

  export { DarkTheme,ScanWalletStyles };