import React, {useState,useEffect} from 'react';
import { ActivityIndicator, ScrollView,TouchableOpacity,FlatList,StyleSheet,Text,TextInput, View, KeyboardAvoidingView,Platform } from 'react-native';
import Amplify, { Auth, withSSRContext } from 'aws-amplify';
import { useFonts, Poppins_500Medium } from '@expo-google-fonts/poppins';
import AppLoading from 'expo-app-loading';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { LightTheme } from './portfolioLightTheme';
import { AddTokenStyles, DarkTheme } from './portfolioDarkTheme';
import Theme from '../theme/theme';


const getUser = async() => {
    var userData = await Auth.currentAuthenticatedUser();
    var email = userData.attributes.email;
    return email;
  }

const InvalidContract = () => 
  ( 
      <View style={{width:'100%'}}>
          <Text style={DarkTheme.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/>  Invalid contract address</Text>

      </View>
      
  )

const InvalidWallet = () => 
  ( 
    <View style={{width:'100%'}}>
        <Text style={DarkTheme.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/>  Invalid wallet address</Text>
    </View>
      
  )
  const NameValidation = () => 
  ( 
      <View style={{width:'100%'}}>
          <Text style={DarkTheme.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/>  We were not able to find that token. Please verify the chain and contract address and try again.</Text>

      </View>
      
  )

  const InputError = () => 
  ( 
      <View style={{width:'100%'}}>
          <Text style={DarkTheme.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/>  Balance input must be numbers</Text>

      </View>
      
  )
  

function AddToken({ navigation,route }) {
    const [contractAddress,setContractAddress] = useState('wallet');
    const [balance,setBalance] = useState(undefined);
    const [wallet,setWallet] = useState(" -- ");
    const [error,setError] = useState(false);
    const [nameValidation,setNameValidation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [prefix,setPrefix] = useState('-- ');
    const [prefixSearching,setPrefixSearching]=useState(undefined);
    const [wallets,setWallets]=useState("-- ");
    const [walletSearching,setWalletSearching]=useState(undefined);
   
    const prefixes = [["ETH"],["BSC"],["Poly"]]


    useEffect(() => {
        load();
        

        }, [])

    async function load(){
        const user = getUser().then((result)=> {
  
          //var Data = {"Command":"wallets","User":result};
          var myHeaders = new Headers();
          myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
          //var raw = JSON.stringify(Data);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
  
          const grab = fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets?email="+result, requestOptions)
          .then(response => response.json())
          .then((
            responseJson) => {console.log('Fetching Wallet Data..');
            var data = responseJson;
            setWallets(data)
            console.log(data);
          });
      });
    }



    async function save(){
        setIsLoading(true);

      var email = await getUser();
      

      if (prefix.toLowerCase() == "eth"){
        var network = 1
      } else if ( prefix.toLowerCase() == "bsc") {
        var netowrk = 56
      } else if( prefix.toLowerCase() == "poly"){
        var network = 137
      }

      var token_id = contractAddress.toLowerCase()+":"+network;
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };
      var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/custom-token/save?email="+email+"&tokenId="+token_id+"&wallet="+wallet+"&balance="+balance, requestOptions)
      var values = await grab.json();
      let data = await values;
      console.log(data);
      if (data.message == 'Internal Server Error'){
          setNameValidation(true);
      } else if (data.result == true){
        navigation.goBack()
      }
      setIsLoading(false);
    }
            
    let [fontsLoaded] = useFonts({
        Poppins_500Medium,
      });
    
      if (!fontsLoaded) {
        return <AppLoading />;
      }       

            
      const Header = () => (
            <View style={DarkTheme.headerBackgroundaddWallet}>
              <TouchableOpacity style={{position:'absolute',paddingRight:300,paddingBottom:8,justifyContent:'center',alignItems:'center'}} onPress={() =>navigation.goBack()}>
                            <Text style={DarkTheme.values}><FontAwesome5 name={'angle-double-left'} style={{fontSize:25,color:Theme.icon_color}} /></Text>
                </TouchableOpacity>
                <Text style={DarkTheme.headerTextaddWallet}>Manual Token</Text>
            </View>
      )
          
        
      const PrefixDropdown = () => (
        
        <View style={{width:135,justifyContent:'center',alignSelf:'center',marginLeft:100,marginTop:-20}}>
        <FlatList
                    style={{width: '100%',marginTop:10,paddingRight:20,marginBottom:"10%"}}
                    data={prefixes}
                    renderItem={({item}) => (
                        
                    <TouchableOpacity 
    
                    onPress={() => {setPrefix(item[0]);setPrefixSearching(false);}}>    
                        <View style={AddTokenStyles.rowContainer}>
                            <View style={{width:'90%'}}>
                            <Text style={AddTokenStyles.rowSymbol}>{item}</Text>
                            </View>
                        </View>
                    </TouchableOpacity> 
                        
    
                        ) }
                    
                    keyExtractor={(item)=> item}
                    />
        </View>
        
    ) 
    
    function formatWallet(wallet) {
        if (wallet != null){
        wallet = wallet.slice(0,5)+'...'+wallet.slice(38);
        //console.log(wallet);

        return wallet;
        }
      }
    const WalletsDropdown = () => (
        <FlatList
        style={{width:350}}
        data={wallets}
        renderItem={({item}) => (
            <TouchableOpacity style={DarkTheme.walletDropdownRowContainer} onPress={() => {setWallet(item.wallet_address); setWalletSearching(false)}}>
                <View style = {{justifyContent:'center', alignItems:'center', width:'50%'}}>
                    <Text style={DarkTheme.labels}>Nickname</Text>
                    <Text style={DarkTheme.walletName}>{item.wallet_name}</Text>
                </View>

                <View style = {{justifyContent:'center', alignItems:'center',width:'50%'}}>
                    <View style={{justifyContent:'center', alignItems:'center',}}>
                        <Text style={DarkTheme.labels}>Address</Text>
                        <Text style={DarkTheme.wallets}>{formatWallet(item.wallet_address)} </Text>
                    </View>
                </View>
          </TouchableOpacity>

          ) }
        extraData={wallets}
        keyExtractor={(item)=> item._id}
        />
        
    )  
    
    return (
        <View  style={DarkTheme.containeraddWallet}>
            <Header />
            <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} >

            
            <ScrollView style={{marginPadding:220,}}>
            <View style={DarkTheme.form}>
            {(nameValidation == true) ? <NameValidation /> : null}
                    <Text style={DarkTheme.t}>Enter a valid Ethereum, Binance Smart Chain, or Polygon contract address. Adding manual tokens is useful if you have tokens staked on other platforms and the tokens are not in your wallet.</Text>
                    
                    <View style={{marginVertical:15}}>
                    
                        <View style={{width:'100%',justifyContent:'center',alignItems:'center'}}>
                            <TouchableOpacity style={{flexDirection:'row'}} onPress={()=> prefixSearching ? setPrefixSearching(false):setPrefixSearching(true)}>
                                
                                <View style={{width:'50%',justifyContent:'center',alignItems:'center'}}>
                                        <Text style={DarkTheme.label}>Token Chain</Text>
                                </View>

                                <View style={AddTokenStyles.textContainer}>
                                        <Text style={DarkTheme.label}>{prefix}</Text>

                                        <FontAwesome5 name={'angle-down'} style={{fontSize:25,color:Theme.icon_color}} />
                                </View>

                            </TouchableOpacity>  
                        </View>
                    
                    </View>
                    {prefixSearching ? <PrefixDropdown /> : null}
                    <Text style={DarkTheme.label}>Token Contract Address</Text>
                    <TextInput
                    style={DarkTheme.textInput}
                    placeholder="0x..."
                    onChangeText={contract => setContractAddress(contract)}
                    />
                    {(contractAddress != 'wallet' && contractAddress.length != 0 && contractAddress.startsWith("0x") == false || contractAddress.length != 42 && contractAddress != 'wallet' && contractAddress.length != 0) ? <InvalidContract /> : null}

                    <Text style={DarkTheme.label}>Balance</Text>
                    <TextInput
                    style={DarkTheme.textInput}
                    placeholder="25777"
                    keyboardType='number-pad'
                    onChangeText={balance => setBalance(balance)}
                    />
                    {(balance && isNaN(balance)) ? <InputError /> : null}
                    
                    
                        <View style={{width:'100%',justifyContent:'center',alignItems:'center'}}>
                            <TouchableOpacity style={{flexDirection:'column',alignItems:'center'}} onPress={()=> walletSearching ? setWalletSearching(false):setWalletSearching(true)}>
                                
                                <View style={{width:'100%',justifyContent:'center',alignItems:'center'}}>
                                        <Text style={DarkTheme.label}>Wallet</Text>
                                </View>

                                <View style={AddTokenStyles.walletTextContainer}>
                                        <View style={{width:'80%'}}>
                                            <Text style={AddTokenStyles.textInput}>{wallet!=" -- " ? formatWallet(wallet): wallet}</Text>
                                        </View>
                                        <View style={{width:'10%'}}>
                                            <FontAwesome5 name={'angle-down'} style={{fontSize:25,color:Theme.icon_color,alignSelf:'flex-end'}} />
                                        </View>
                                </View>

                            </TouchableOpacity>  
                        </View>
                    
                    
                   
                    {walletSearching ? <WalletsDropdown /> : null}
                    {(wallet != ' -- ' && wallet.length != 0 && wallet.startsWith("0x") == false || wallet.length != 42 && wallet != ' -- ' && wallet.length != 0) ? <InvalidWallet /> : null}

                    <TouchableOpacity
                        style={DarkTheme.button}
                        onPress={save}
                        disabled={contractAddress != 'wallet' && contractAddress.length != 0 && contractAddress.startsWith("0x") == false || contractAddress.length != 42 && contractAddress != 'wallet' && contractAddress.length != 0 ? true : contractAddress != 'wallet' && balance && prefix != '--' && wallet != 'wallet' ? false : true}
                        >
                        {isLoading && <ActivityIndicator size="small" color="#fff" />}
                        <Text style={DarkTheme.buttonText}>Save Token</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
            </KeyboardAvoidingView>

        
        </View>
  
    )
  }

export default AddToken;

