import React, {useState,useEffect,componentDidMount} from 'react';
import {ActivityIndicator, Dimensions } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { ScrollView,Image,TouchableOpacity,FlatList,StyleSheet,Text, View,RefreshControl,Linking } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { LineChart } from "react-native-chart-kit";
import { Auth} from 'aws-amplify';
import { useFonts, Poppins_500Medium} from '@expo-google-fonts/poppins'
import { DarkTheme, ChartColors, Icon_color } from './portfolioDarkTheme';
import { LightTheme } from './portfolioLightTheme';
import AppLoading from 'expo-app-loading';
import Menu from '../components/menu';

import * as Notifications from 'expo-notifications';

import Animated, {
  FadeInUp,
  FadeInLeft,
  FadeOutUp,
  FadeOutDown,
  FadeOutLeft,
  FadeInRight,
  FadeInDown,
} from 'react-native-reanimated';



const getUser = async() => {
  var userData = await Auth.currentAuthenticatedUser();
  var email = userData.attributes.email;
  return email;
}

function TokenDetails({route,navigation}) {
    const [transactions,setTransactions]=useState([]);
    const [tokens,setTokens]=useState([]);
    const [wallets,setWallets]=useState([]);
    const [chart,setChart]=useState(7);
    const [change,setChange]=useState(0);
    const [noData,setNoData]=useState(false);
    const [loadingTransactions,setLoadingTransactions]=useState(true);
    const [loadingTransactionsError,setLoadingTransactionsError]=useState(false);
    const [fetchingTransactions,setFetchingTransactions]=useState(false);
    const [fetchingError,setFetchingError]=useState(false);
    const [cost,setCost]=useState(0.00);
    const [dca,setDca]=useState(0.00);
    const [toggle,setToggle]=useState("PL");
    const [voteStatus,setVoteStatus]=useState(route.params.item.weekly_voted);
    const [prices,setPrices] = useState({'labels':['loading'], datasets:[{data: [0.0002,]}]});
    const [decimalCount,setDecimalCount]=useState(6);
    const [chartPadding,setChartPadding]=useState(85);
    const [noPriceData,setNoPriceData]=useState(false);
    const [dropdown,setDropdown]=useState(false);
    const [updatingBalances,setUpdatingBalances]=useState(0);
    const [walletBalances,setWalletBalances]=useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [notifications, setNotifications] = useState(false);
    const [percent,setPercent] = useState(undefined)
    const [socials,setSocials] = useState(false);
    const [tokenInfo,setTokenInfo] = useState(false);




    useEffect(() => {
      //openWsConnection();
      checkData();
      getWalletBreakdown();
      checkTransactions();

    }, [route.params.item.contract])

    const onRefresh = () => {
      checkData();
      getWalletBreakdown();
      checkTransactions();
    };

    async function getPrices(days){
      console.log(route.params.item)
      setPrices({'labels':['loading'], datasets:[{data: [0.0002,]}]});
      setChart(days);
      //var body = await {
      //  "contract": route.params.item.contract,"chain":route.params.item.chain,"days":days,"currencyRate":route.params.currency.currency_rate
      //};
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/tokens/line-chart?tokenId="+route.params.item._id+"&days="+days, requestOptions)
        .then(response => response.text())
        .then(result => {
          var data = JSON.parse(result)
          console.log(data);
          if(data.datasets[0].data.length > 1){
            setPrices(data);
          //console.log(prices);
          percentageChange(data);
          } else {
            setNoPriceData(true)
          }
        })
        .catch(error => console.log('error', error));
      //console.log(data);



    }

    async function checkData() {

      if (route.params.item.price == 0.0){
        //console.log('nah bruh');
        route.params.item.price ==0;

      } else {
        getPrices(7);
        var zeros = 0;
        var num = parseFloat(route.params.item.price)
        while(num < 1){
          zeros++;
          num *= 10;

        }

        //console.log("zeros is :"+zeros);
        setDecimalCount(zeros+2);
        zeros <= 1 ? setChartPadding(55) : zeros <= 5 ? setChartPadding(75) : zeros <= 7 ? setChartPadding(90):zeros <= 10 ? setChartPadding(115): setChartPadding(140);
      }
    }

    


    async function checkTransactions(data,user){
      //console.log('im trying')
      setLoadingTransactions(true);

      if(route.params.item.processed == true){
        await getTransactions(user);
        setLoadingTransactions(false);


      }
      else if (route.params.item.processed == false){
        //console.log('processing transactions');
        setFetchingTransactions(true);
        let response = await processTransactions(user);
        //console.log(response);
        if (response.buyTransactions > 0 || response.transferTransactions > 0){
          var trans = await getTransactions(user);
          if(trans.length < 0.1){
            setLoadingTransactionsError(true)
          }
          route.params.item.processed=true;
          setLoadingTransactions(false);
          setFetchingTransactions(false);

        } else {
          setFetchingTransactions(false);
          setFetchingError(true);
        

        }

        
      }


    }
    async function Cost(values){
        var c = 0.0
        var r = 0
        //console.log(values.length);
        for (let x of values){
              if (!x.ignore){
                    if(x.spent_total_manual) {
                      c += parseFloat(x.spent_total) + parseFloat(x.spent_total_manual)
                    } else{
                    c +=parseFloat(x.spent_total);
                    }
                    //console.log(x.receive_value);
                    r +=parseFloat(x.received_value)
            }
      }
        //console.log(r,route.params.item.balance);

        let dca = c/r;
        let totalCost = dca*route.params.item.balance;
        //console.log(totalCost);

        setDca(dca);
        setCost(totalCost);
    }

    async function getTransactions(user){
        console.log('Getting Transactions')
        var email = await getUser();
        var body = {"contract": route.params.item.contract,"user":email,"chain":route.params.item.chain};
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/load-transactions?email="+email+"&contract="+route.params.item.contract.toLowerCase()+"&chain="+route.params.item.chain, requestOptions)
        var values = await grab.json();
        try{
        //console.log(values);
        setTransactions(values);
        await Cost(values);
        return values;
        } catch (err){
          setFetchingError(true);
        }


      }

      const decimalCheck = (num) => {
        if (num > 0) {
        var zeros = 0;
        var num = parseFloat(num)
        if (num < 0.01){
        while(num < 1){
          zeros++;
          num *= 10;
  
        }
        return zeros-1
  
      } else {
        return 0
      }
    } else {return 0}
  
        //console.log("zeros is :"+zeros);
        //setDecimalCount(zeros+2);
      }
  
      const priceFormat = (num,zeros) => {
  
        num = num.toFixed(18)
        num = num.toString().slice(zeros)
        num = num.slice(0,5)
        return num
  
        //console.log("zeros is :"+zeros);
        //setDecimalCount(zeros+2);
      }

    async function processTransactions(wallets,user){
        var email = await getUser();
        var body = {};
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
        var raw = JSON.stringify(body);
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };
        //"user":email,"contract":route.params.item.contract.toLowerCase(),"symbol":route.params.item.symbol,"decimals":route.params.item.decimals,"chain":route.params.item.chain,"wallet":wallets
        var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/process-token-transactions?email="+email+"&contract="+route.params.item.contract.toLowerCase()+"&decimals="+route.params.item.decimals+"&symbol="+route.params.item.symbol+"&chain="+route.params.item.chain+"&tokenId="+route.params.item._id, requestOptions)
        var values = await grab.json();
        console.log(values);
        return values

    }

    async function vote(vote){
      var email = await getUser();
      setVoteStatus(true);
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };
      var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/tokens/vote?action="+vote+"&email="+email+"&tokenId="+route.params.item._id, requestOptions)
      var d = await grab.json();
      console.log(d)
      route.params.item.weekly_voted = true;


    }

    const registerForPushNotificationsAsync = async (action) => {

      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);
      //this.setState({ expoPushToken: token });


    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
    var email = await getUser();

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
    if (percent == 5){
      var sub = "five_percent"
    } else if (percent == 10){
      var sub = "ten_percent"
    } else if (percent == 15){
      var sub = "fifteen_percent"
    }

    var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/subscribe/mobile?token_id="+route.params.item._id+"&action="+action+"&pushToken="+token+"&email="+email+"&subscription="+sub, requestOptions);
    var d = await grab.json();
    console.log(d)

    if (action == 'find'){
      if (d.subscription == "five_percent"){
        setPercent(5);
      } else if (d.subscription == "ten_percent"){
        setPercent(10);
      } else if (d.subscription == "fifteen_percent"){
        setPercent(15);
      }
    }
      
    };

    async function getWalletBreakdown(){
      var email = await getUser();
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/holdings/token?email="+email+"&tokenId="+route.params.item._id, requestOptions);
      console.log("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/holdings/token?email="+email+"&tokenId="+route.params.item._id)
      var d = await grab.json();
      console.log(d)
      setWallets(d)


    }

    async function updateBalance(){
      setUpdatingBalances(1);
      //console.log('getting tokens by contract');
      var newBalance = 0
      
      var email = await getUser();
      if (route.params.item.chain == 1) {
        var net = "eth"
      } else if (route.params.item.chain == 56) {
        var net = "bsc"
      } else if (route.params.item.chain == 137) {
        var net = "poly"
      }
    //console.log(body);
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };
      var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/token/update-balance?email="+email+"&contract="+route.params.item.contract+"&chain="+net, requestOptions)
      var d = await grab.json();
      
      console.log(d);
      newBalance += d.balance;

    
    route.params.item.balance = parseFloat(newBalance);
    setUpdatingBalances(2);
    await new Promise(resolve => setTimeout(resolve, 4000));
    setUpdatingBalances(0);

    

    }

    const chartConfig = {
      paddingRight: chartPadding,

      backgroundColor: ChartColors.background,
      backgroundGradientFrom: ChartColors.gradientFrom,
      backgroundGradientTo: ChartColors.gradientTo,
      decimalPlaces: decimalCount, // optional, defaults to 2dp
      color: (opacity = 1) => ChartColors.color,
      labelColor: (opacity = 1) => ChartColors.labelColor,
      style: {
        borderRadius: 16
      },

    }



    // //DEFAULT LIGHT THEME (CTRL+K+U to uncomment)

    // const chartConfig = {
    //   paddingRight: 125,

    //   backgroundColor: "#f6f6f6",
    //   backgroundGradientFrom: "#f6f6f6",
    //   backgroundGradientTo: "#f6f6f6",
    //   decimalPlaces: 12, // optional, defaults to 2dp
    //   color: (opacity = 1) => `rgba(73, 193, 124, ${opacity})`,
    //   labelColor: (opacity = 1) => `rgba(111, 111, 111, ${opacity})`,
    //   style: {
    //     borderRadius: 16
    //   },

    // }

    // const chartConfig2 = {
    //   paddingRight: 65,

    //   backgroundColor: "#f6f6f6",
    //   backgroundGradientFrom: "#f6f6f6",
    //   backgroundGradientTo: "#f6f6f6",
    //   decimalPlaces: 2, // optional, defaults to 2dp
    //   color: (opacity = 0) => `rgba(73, 193, 124, ${opacity})`,
    //   labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    //   style: {
    //     borderRadius: 16
    //   },



    async function percentageChange(data){
      let d = data.datasets[0].data
      let oldValue =data.datasets[0].data[0];
      let newValue = data.datasets[0].data[(d.length - 1)];
      console.log('current price: '+newValue+" old price: "+oldValue);
      let change = (newValue - oldValue)/oldValue;
      change = change * 100;
      setChange(change);
      return change;
    }

    const Images = {
      "bsc":require( './../img/logos/bnb.png'),
      "eth":require( './../img/logos/eth.png'),
      "poly":require( './../img/logos/poly.png')

    }

    const Chart = () => (
      <View style= {{width:'100%',height:220,marginHorizontal:20,}}>

          <LineChart
          data={prices}
          withVerticalLabels={false}
          withVerticalLines={false}
          onDataPointClick={()=>{console.log('masoud')}}

          withDots={false}
          width={Dimensions.get("window").width}
          height={220}
          bezier
          chartConfig={chartConfig}
          />
      </View>
    )

    function nFormatter(num) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "Q" },
        { value: 1e18, symbol: "Q" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
      });
      return item ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol : "0";
    }

    const TokenInfo = () => (
    <View style={DarkTheme.aboutContainer}>
            <Text style={DarkTheme.infoTitle}>{route.params.item.name} Info</Text>
            <View style={route.params.item.listed == true ? [DarkTheme.dataContainer,DarkTheme.infoData] : [DarkTheme.dataContainer,{justifyContent:'space-around'}]}>

                <View style={DarkTheme.dataColumn}>
                  <TouchableOpacity style={{justifyContent:'center', alignItems:'center',}}onPress={() => {Clipboard.setString(route.params.item.contract)}}>
                    <Text style={DarkTheme.labels}>Contract</Text>
                    <Text style={DarkTheme.values}>{route.params.item.contract.slice(0,4)+'...'+route.params.item.contract.slice(38)} <FontAwesome5 name={'copy'} style={{fontSize:15}} /></Text>
                  </TouchableOpacity>
                </View>

                <View style={[DarkTheme.dataColumn,{paddingTop:5}]}>
                <Image
                                      source={(route.params.item.chain == 56) ? Images.bsc : (route.params.item.chain == 1) ? Images.eth : Images.poly}
                                      style={DarkTheme.imgStyle}
                                      ></Image>
                </View>
                <View style={DarkTheme.dataColumn}>

                <Text style={DarkTheme.labels}>Total Supply</Text>
                  <Text style={DarkTheme.values}>{nFormatter(route.params.item.totalSupply)}</Text>


                </View>


              </View>
            {route.params.item.listed == true ?
            <View style={[DarkTheme.dataContainer,DarkTheme.infoData]}>


                <View style={DarkTheme.infoColumn}>
                    <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>Linking.openURL(route.params.item.website)}>
                        <Text style={DarkTheme.values}><FontAwesome5 name={'link'} style={{fontSize:15}} />  Website</Text>
                    </TouchableOpacity>
                </View>

                <View style={DarkTheme.infoColumn}>
                    <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>Linking.openURL((route.params.item.chain == 56) ? explorers.bsc : (route.params.item.chain == 1) ? explorers.eth : explorers.poly)}>
                        <Text style={DarkTheme.values}><FontAwesome5 name={'wpexplorer'} style={{fontSize:18}} />  Explorer</Text>
                    </TouchableOpacity>
                </View>
                <View style={DarkTheme.infoColumn}>
                    <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>{(!socials)? setSocials(true): setSocials(false)}}>
                        <Text style={DarkTheme.values}><FontAwesome5 name={(socials) ? 'caret-up' : 'caret-down'} style={{fontSize:15}} />  Socials</Text>
                    </TouchableOpacity>
                </View>




            </View>
            : null }
            {(socials) ? <Socials /> : null}
            {route.params.item.listed == true ?
            <>
                    <Text style={DarkTheme.descriptionTitle}>About </Text>
                    <Text style={DarkTheme.description}>{route.params.item.description}</Text>
            </>  
                    :null}
        </View>
       
        
    )

    const Socials = () => (

      <View style={DarkTheme.infoContainer}>

          {route.params.itemtwitter ?
          <View style={DarkTheme.infoColumn}>
              <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>Linking.openURL(route.params.item.twitter)}>
                  <Text style={DarkTheme.values}><FontAwesome5 name={'twitter'} style={{fontSize:15}} />  Twitter</Text>
              </TouchableOpacity>
          </View>
          : null
          }
          {route.params.item.discord ?
          <View style={DarkTheme.infoColumn}>
            <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>Linking.openURL(route.params.item.discord)}>
                <Text style={DarkTheme.values}><FontAwesome5 name={'discord'} style={{fontSize:18}} />  Discord</Text>
            </TouchableOpacity>
          </View>
          : null
          }
          {route.params.item.telegram ?
          <View style={DarkTheme.infoColumn}>
              <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>Linking.openURL(route.params.item.telegram)}>
                  <Text style={DarkTheme.values}><FontAwesome5 name={'telegram'} style={{fontSize:15}} />  Telegram</Text>
              </TouchableOpacity>
          </View>
          : null }




      </View>
      )     

    const GrabbingTransactions = () => (
      <View style= {{width:'100%',alignItems:'center',marginVertical:5,flexDirection:'row',justifyContent:'center'}}>
        <View>
              <ActivityIndicator size='small' color={`rgba(150, 150, 150, 1)`} />

        </View>
            <View style={{width:'80%',justifyContent:'center',alignItems:'center'}}>
                <Text style={DarkTheme.values}>Gathering your {route.params.item.symbol} transactions.</Text>

            </View>
      </View>
    )


    const UpdatingBalances = () => (
      <Animated.View style= {{width:'100%',alignItems:'center',marginVertical:5,flexDirection:'row',justifyContent:'center'}} entering={FadeInRight} exiting={FadeOutLeft}>
        <View>
              {(updatingBalances == 1) ? <ActivityIndicator size='small' color={`rgba(150, 150, 150, 1)`} /> : (updatingBalances == 2) ? <FontAwesome5 name={'check-circle'} style={{fontSize:15,color:'#699e71'}}/> : null}

        </View>
            <View style={{width:'80%',justifyContent:'center',alignItems:'center'}}>
            {(updatingBalances == 1) ? <Text style={DarkTheme.values}>Updating your {route.params.item.symbol} balance.</Text> : (updatingBalances == 2) ?
                <Text style={DarkTheme.values}>Success. Your {route.params.item.symbol} balance is up to date.</Text>: null }

            </View>
      </Animated.View>
    )

    const FetchingTransactionsError = () => (
      <View style={{width:'100%',margin:15,paddingHorizontal:10,justifyContent:'center',alignItems:'center'}}>
          <Text style={DarkTheme.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/>  No {route.params.item.symbol} transactions found.</Text>

      </View>
    )

    const ChartLoading = () => (
      <View style= {{width:'100%',height:220,justifyContent:'center',marginHorizontal:20,}}>

       <ActivityIndicator size='large' color={(opacity = 2) => `rgba(80, 87, 133, ${opacity})`} />
      </View>
    )

    const ChartFailed = () => (
      <View style= {{width:'100%',height:220,justifyContent:'center',marginHorizontal:20,alignItems:'center'}}>
       <Text style={DarkTheme.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/> No Price Data Found.</Text>

      </View>
    )

    let [fontsLoaded] = useFonts({
      Poppins_500Medium,
    });

    if (!fontsLoaded) {
      return <AppLoading />;
    }

    


    const Header = () => (
      <View style={DarkTheme.portfolioHeaderBackground}>
              <View style={{paddingBottom:7,width:30}}>
                <TouchableOpacity onPress={async() =>{ !notifications ? navigation.goBack() : setNotifications(false)}}>
                            <Text style={DarkTheme.values}><FontAwesome5 name={'angle-double-left'} style={{fontSize:25,color:Icon_color}} /></Text>
                </TouchableOpacity>
              </View>
              <View>
                  <Text style={DarkTheme.headerText}>Your {route.params.item.symbol.toUpperCase()}</Text>
              </View>

              <View style={{paddingBottom:9}}>
                <TouchableOpacity style={{paddingBottom:2,width:30,alignItems:'center'}} onPress={() =>{dropdown ?setDropdown(false) :setDropdown(true)}}>
                            <Text style={DarkTheme.values}><FontAwesome5 name={'caret-down'} style={{fontSize:25,color:Icon_color}} /></Text>
                </TouchableOpacity>
              </View>

          </View>
    )

    

    

    const CommunityVote = () => (
      <View style={DarkTheme.voteContainer}>

            <View style={DarkTheme.communityContainer}>
              <View style={DarkTheme.dataColumn}>
                <Text style={DarkTheme.values}>Community Vote</Text>
              </View>

              <View style={DarkTheme.dataColumn}>
              <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() => {vote("upvote");}}>

                <FontAwesome5 name={'laugh-beam'} style={{fontSize:25,color:'#49C07D'}}/>

                </TouchableOpacity>
              </View>
              <View style={DarkTheme.dataColumn}>
              <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() => {vote("downvote");}}>

              <FontAwesome5 name={'frown-open'} style={{fontSize:25,color:'#3F4Ed1'}}/>

              </TouchableOpacity>
              </View>


            </View>

        </View>
    )

    const dotImages = {
      "left":require( './../../assets/icons/left.png'),
      "middle":require( './../../assets/icons/middle.png'),
      "right":require( './../../assets/icons/right.png')

    }

    

    return (
      <View style={DarkTheme.container}>
          <Header />
          {dropdown ?
          <View style={DarkTheme.dropdownContainer}>
            

              <View style={DarkTheme.dropdownDataContainer}>

                  <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={async() => {route.params.item.processed = 0; setTransactions([]); checkTransactions(); setDropdown(false)}}>
                      <Text style={DarkTheme.values}>Rescan Transactions</Text>
                  </TouchableOpacity>

              </View>
              <View style={DarkTheme.dropdownDataContainer}>

                  <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={async() => {updateBalance(); setDropdown(false)}}>
                      <Text style={DarkTheme.values}>Update Balance</Text>
                  </TouchableOpacity>

              </View>

              

             


          </View>
       : null}
       <Menu props={{"navigation": navigation}}/>
       {notifications ? 
            <View style={{width:'80%',height:'100%',marginTop:'5%'}}>

                
            <View style={{}}>
              <View style={{marginBottom:'7%'}}>
              <Text style={{
                color:'#fff',
                fontFamily: 'Poppins_500Medium',
                fontSize:19,
              }}>Price Notifications</Text>
              </View>
              <View style={DarkTheme.rangeContainer2}>

                    <View style={DarkTheme.dataColumn}>
                      <TouchableOpacity style={(percent == 5) ? DarkTheme.activeNotifications : DarkTheme.inactiveNotifications} onPress={() => {setPercent(5)}}>
                        <Text  style={(percent == 5) ? DarkTheme.activeRange2 : DarkTheme.inactiveRange2}>5%</Text>
                      </TouchableOpacity>
                    </View>

                    <View style={DarkTheme.dataColumn}>
                      <TouchableOpacity style={(percent == 10) ? DarkTheme.activeNotifications : DarkTheme.inactiveNotifications} onPress={() => {setPercent(10)}}>
                        <Text  style={(percent == 10) ? DarkTheme.activeRange2 : DarkTheme.inactiveRange2}>10%</Text>
                      </TouchableOpacity>
                    </View>

                    <View style={DarkTheme.dataColumn}>
                      <TouchableOpacity style={(percent == 15) ? DarkTheme.activeNotifications : DarkTheme.inactiveNotifications} onPress={() => {setPercent(15)}}>
                        <Text  style={(percent == 15) ? DarkTheme.activeRange2 : DarkTheme.inactiveRange2}>15%</Text>
                      </TouchableOpacity>
                    </View>


              </View>

              <View style={{marginTop:'7%'}}>
                {percent ? 
              <Text style={{
                color: '#505785',
                fontFamily: 'Poppins_500Medium',
                fontSize:15,
              }}>You will receive {route.params.item.name} notifications when it either climbs or falls {percent}%</Text>
              : 
              <Text style={{
                color: '#505785',
                fontFamily: 'Poppins_500Medium',
                fontSize:15,
              }}>Please select an price update option above.</Text>}
              </View>
              
              <View style={{width:'100%',paddingTop:'10%',flexDirection:'row',justifyContent:'space-evenly'}}>
                  <View style={{width:'35%',paddingTop:5}}>

                  <TouchableOpacity  style={{flexDirection:'row',
                    justifyContent:'center',
                    alignItems:'center',
                    backgroundColor:'#CF6679',
                    paddingVertical:7,
                    marginLeft:12,
                    marginTop:-7,
                    width:'100%',
                    borderColor:'#CF6679',
                    borderWidth:1,
                    borderRadius:150}} onPress={async() => {registerForPushNotificationsAsync("unsubscribe"); setPercent(false); setNotifications(false)}}>
                                <FontAwesome5 name={'times-circle'} style={{fontSize:20,color:"#fff"}} /><Text style={DarkTheme.values}>  Disable </Text>
                    </TouchableOpacity>
                    
                    
                    
                </View>

                <View style={{width:'35%',paddingTop:5}}>
                    <TouchableOpacity style={{flexDirection:'row',
                        justifyContent:'center',
                        alignItems:'center',
                        backgroundColor:'#699e71',
                        paddingVertical:7,
                        marginTop:-7,
                        width:'100%',
                        borderColor:'#699e71',
                        borderWidth:1,
                        borderRadius:150}} onPress={async() =>{await registerForPushNotificationsAsync("subscribe"); setNotifications(false);}}>
                                    <FontAwesome5 name={'check-circle'} style={{fontSize:20,color:"#fff"}} /><Text style={DarkTheme.values}>  Save </Text>
                        </TouchableOpacity>
                    
                    
                </View>
              </View>
            </View>

            


          </View>
       : null}
        {updatingBalances != 0 ? <UpdatingBalances /> : null}
        <ScrollView
        style={{width:'100%',height:'100%'}}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />}
          >

        <View style={{flexDirection:'row', height: 100, width:'100%'}}>
          <View style={{flexDirection:'row', justifyContent:'center',alignItems:'center', height: 100,width:'25%'}}>
              <Image
              source={{uri: route.params.item.url}}
              style={{width: 70,
                height: 70,

                resizeMode:'contain',

                }}
              ></Image>

          </View>

          <TouchableOpacity style={{flexDirection:'column',justifyContent:'center', height: 100,width:'45%'}} onPress={() => {tokenInfo ? setTokenInfo(false) : setTokenInfo(true)}}>
              <Text style={DarkTheme.name}>{route.params.item.name}  <FontAwesome5 name={'caret-down'} style={{fontSize:20,color:'#fff'}} /></Text>
              {route.params.item.price > 0.001 ?
              <Text style={DarkTheme.price}>{(route.params.item.price >= 1) ? (parseFloat(route.params.item.price)*parseFloat(route.params.currency.currency_rate)).toFixed(5).toString().slice(0,11) : (parseFloat(route.params.item.price)*parseFloat(route.params.currency.currency_rate)).toFixed(20).toString().slice(0,8)}</Text>
              :
              <View style={{width:'100%',flexDirection:'row'}}>
                        <Text style={DarkTheme.price}>{(parseFloat(route.params.item.price)*parseFloat(route.params.currency.currency_rate)) < 0.01 ? "0.0" : null}</Text>
                        <Text style={[DarkTheme.price,{paddingTop:11,fontSize:10,marginHorizontal:1}]}>{(parseFloat(route.params.item.price)*parseFloat(route.params.currency.currency_rate)) < 0.01 ? decimalCheck(parseFloat(route.params.item.price)*parseFloat(route.params.currency.currency_rate)) : null}</Text>
                        <Text style={DarkTheme.price}>{(parseFloat(route.params.item.price)*parseFloat(route.params.currency.currency_rate)) < 0.01 ? priceFormat(parseFloat(route.params.item.price)*parseFloat(route.params.currency.currency_rate),decimalCheck(parseFloat(route.params.item.price)*parseFloat(route.params.currency.currency_rate))+2): route.params.currency.currency_prefix+(parseFloat(route.params.item.usd_price)*parseFloat(route.params.currency.currency_rate)).toFixed(5)} </Text>
                    </View>
              }
          </TouchableOpacity>

          <View style={{flexDirection:'column',justifyContent:'center',alignItems:'flex-start', height: 100,width:'30%',}}>
              <Text style={(change >= 0) ? DarkTheme.percentageChangePositive : DarkTheme.percentageChangeNegative}>{change.toFixed(2)}%</Text>
              
          </View>


        </View>
        {tokenInfo ? <TokenInfo /> : null}
        {(voteStatus == false && route.params.item.listed == 1) ? <CommunityVote /> : null}

        <View style={DarkTheme.dataContainer}>

          <View style={DarkTheme.dataColumn}>
            <Text style={DarkTheme.labels}>Value</Text>
            <Text  style={DarkTheme.values}>{route.params.currency.currency_prefix} {route.params.hideValues ? '...' :((parseFloat(route.params.item.balance)*parseFloat(route.params.item.price))*parseFloat(route.params.currency.currency_rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
          </View>

          <View style={DarkTheme.dataColumn}>
            <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() => {walletBalances?setWalletBalances(false):setWalletBalances(true) }}>
              <Text style={DarkTheme.labels}>Balance {walletBalances ? <FontAwesome5 name={'caret-up'} style={{fontSize:12,color:'#505785'}}/>:<FontAwesome5 name={'caret-down'} style={{fontSize:12,color:'#505785'}}/>}</Text>
              <Text style={DarkTheme.values}>{route.params.hideValues ? '...' : parseFloat(route.params.item.balance).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>

            </TouchableOpacity>
          </View>

          <View style={DarkTheme.dataColumn}>
            <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() => {(toggle == 'PL')?setToggle('Cost'): (toggle == 'Cost')?setToggle('DCA'): setToggle('PL')}}>
              <Text style={DarkTheme.labels}>{(toggle == 'PL')? "Profit/Loss" :(toggle=='Cost')? "Cost": (toggle =='DCA')? "DCA":null}</Text>
              <Text style={(!cost) || (toggle == 'Cost') || (toggle == 'DCA')? DarkTheme.values :((parseFloat(route.params.item.value)-parseFloat(cost)) >= 0)?DarkTheme.percentageChangePositive :DarkTheme.percentageChangeNegative}>{(!cost || route.params.hideValues)? "...":(toggle == 'PL') ? ((parseFloat(route.params.item.value)-parseFloat(cost))*parseFloat(route.params.currency.currency_rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):(toggle == "Cost")? (parseFloat(cost)*parseFloat(route.params.currency.currency_rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):
                  <View style={{width:'100%',flexDirection:'row'}}>
                        <Text style={[DarkTheme.price,{color:DarkTheme.values}]}>{(parseFloat(dca)*parseFloat(route.params.currency.currency_rate)) < 0.01 ? "0.0" : null}</Text>
                        <Text style={[DarkTheme.price,{paddingTop:11,fontSize:10,marginHorizontal:1,color:DarkTheme.values}]}>{(parseFloat(dca)*parseFloat(route.params.currency.currency_rate)) < 0.01 ? decimalCheck(parseFloat(dca)*parseFloat(route.params.currency.currency_rate)) : null}</Text>
                        <Text style={[DarkTheme.price,{color:DarkTheme.values}]}>{(parseFloat(dca)*parseFloat(route.params.currency.currency_rate)) < 0.01 ? priceFormat(parseFloat(dca)*parseFloat(route.params.currency.currency_rate),decimalCheck(parseFloat(dca)*parseFloat(route.params.currency.currency_rate))+2): route.params.currency.currency_prefix+(parseFloat(dca)*parseFloat(route.params.currency.currency_rate)).toFixed(5)} </Text>
                    </View>}
                    
              </Text>
              <Image
                      source={(toggle == 'PL' ? dotImages.left : (toggle == "Cost")? dotImages.middle : dotImages.right)}
                      style={{width: 50,
                        height: 20,
                        marginBottom:5,

                      }}
                      resizeMode={'contain'}
                    ></Image>
            </TouchableOpacity>
          </View>



        </View>
        <View style={{marginBottom:20,marginTop:-10}} >
          {walletBalances ?
          <Animated.View style={{width:'100%'}} entering={FadeInRight} exiting={FadeOutLeft}>
                <FlatList

                style={{width: '100%',color:'#fff'}}
                data={wallets}
                renderItem={({item}) => (
                  <View style={DarkTheme.walletBalanceRowContainer}>
                      {item.tokens.manual_token_balance <= 0 ?
                      <View style={{alignItems:'center',justifyContent:'center'}}>
                        <Text style={DarkTheme.labels}>Name</Text>
                        <Text style={DarkTheme.values}>{item.wallet_name}</Text>
                      </View>
                      : null }
                      <View style={{alignItems:'center',justifyContent:'center'}}>
                        <Text style={DarkTheme.labels}>Wallet</Text>
                        <Text style={DarkTheme.values}>{item.wallet_address.slice(0,5)+'...'+item.wallet_address.slice(38)}</Text>

                      </View>
                      <View style={{alignItems:'center',justifyContent:'center'}}>
                        <Text style={DarkTheme.labels}>Balance</Text>
                        <Text style={DarkTheme.values}>{parseFloat(item.tokens.balance).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                      </View>
                      {item.tokens.manual_token_balance > 0 ?
                      <View style={{alignItems:'center',justifyContent:'center'}}>
                        <Text style={DarkTheme.labels}>Manual Balance</Text>
                        <Text style={DarkTheme.values}>{parseFloat(item.tokens.manual_token_balance).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                      </View>
                      : null }
                  </View>


                  ) }

                keyExtractor={(item)=> item.wallet_address}
                />
          </Animated.View> : null}
        </View>



        {(prices.labels[0] == 'loading' && !noPriceData) ? <ChartLoading /> : noPriceData ? <ChartFailed /> : <Chart />}


        <View style={DarkTheme.rangeContainer}>
          <View style={DarkTheme.dataColumn}>
            <TouchableOpacity style={(chart == 90) ? DarkTheme.activeChart : DarkTheme.inactiveChart} onPress={() => {getPrices(90)}}>
              <Text  style={(chart == 90) ? DarkTheme.activeRange : DarkTheme.inactiveRange}>90d</Text>
            </TouchableOpacity>
          </View>

          <View style={DarkTheme.dataColumn}>
            <TouchableOpacity style={(chart == 60) ? DarkTheme.activeChart : DarkTheme.inactiveChart} onPress={() => {getPrices(60)}}>
              <Text  style={(chart == 60) ? DarkTheme.activeRange : DarkTheme.inactiveRange}>60d</Text>
            </TouchableOpacity>
          </View>

          <View style={DarkTheme.dataColumn}>
          <TouchableOpacity style={(chart == 30) ? DarkTheme.activeChart : DarkTheme.inactiveChart} onPress={() => {getPrices(30)}}>
              <Text  style={(chart == 30) ? DarkTheme.activeRange : DarkTheme.inactiveRange}>30d</Text>
            </TouchableOpacity>
          </View>

          <View style={DarkTheme.dataColumn}>
            <TouchableOpacity style={(chart == 7) ? DarkTheme.activeChart : DarkTheme.inactiveChart} onPress={() => {getPrices(7)}}>
              <Text  style={(chart == 7) ? DarkTheme.activeRange : DarkTheme.inactiveRange}>7d</Text>
            </TouchableOpacity>
          </View>


        </View>


        <View style={DarkTheme.transactionsContainer}>
            <Text style={DarkTheme.transactionsHeader}>Transactions</Text>
            {fetchingTransactions ? <GrabbingTransactions /> : null}
            {fetchingError || loadingTransactionsError ? <FetchingTransactionsError/> :null}
        </View>

        <FlatList

        style={{width: '100%',color:'#fff'}}
        data={transactions}
        extraData={transactions}
        refreshControl={
          <RefreshControl
           refreshing={loadingTransactions}
           onRefresh={() => {checkTransactions(wallets);}}
           tintColor="#FFF"

          />}
        renderItem={({item}) => (

        <TouchableOpacity

        onPress={() => navigation.navigate('Transactions',{item: item,currency: route.params.currency})}>
          <View style={DarkTheme.rowContainer}>
              <View style={{width:'33%',alignItems:'center',justifyContent:'center'}}>
                <Text style={DarkTheme.labels}>Tokens Received</Text>
                <Text style={DarkTheme.values}>{item.received_value > 1 ? parseFloat(item.received_value).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parseFloat(item.received_value).toFixed(5)}</Text>
              </View>
              <View style={{width:'33%',alignItems:'center',justifyContent:'center'}}>
                <Text style={DarkTheme.labels}>Price</Text>
                {item.spent_total_manual ?
                <Text style={DarkTheme.values}>{route.params.currency.currency_prefix} {route.params.hideValues ? '...' :((parseFloat(item.spent_total)*parseFloat(route.params.currency.currency_rate))+parseFloat(item.spent_total_manual)).toFixed(2)}</Text>
                :
                <Text style={DarkTheme.values}>{route.params.currency.currency_prefix} {route.params.hideValues ? '...' :(parseFloat(item.spent_total)*parseFloat(route.params.currency.currency_rate)).toFixed(2)}</Text>
                }
              </View>
              <View style={{width:'33%',alignItems:'center',justifyContent:'center'}}>
                <Text style={DarkTheme.labels}>Current Value</Text>
                <Text style={DarkTheme.values}>{route.params.currency.currency_prefix} {route.params.hideValues ? '...' :((parseFloat(item.received_value)*parseFloat(route.params.item.price))*parseFloat(route.params.currency.currency_rate)).toFixed(2)}</Text>
              </View>




          </View>
        </TouchableOpacity>


          ) }

        keyExtractor={(item)=> item._id}
        />







        </ScrollView>

      </View>


    );
  }

  export default TokenDetails;
