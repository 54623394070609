import React, {useState,useEffect,componentDidMount} from 'react';
import {ActivityIndicator, Dimensions } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { ScrollView,Image,TouchableOpacity,FlatList,StyleSheet,Text, View,Linking } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { LineChart } from "react-native-chart-kit";
import { Auth} from 'aws-amplify';
import { DarkTheme } from './marketsDarkTheme';
import { useFonts, Poppins_500Medium } from '@expo-google-fonts/poppins';
import AppLoading from 'expo-app-loading';
import Menu from '../components/menu';
import { ChartColors, Icon_color } from '../portfolio/portfolioDarkTheme';






const getUser = async() => {
  var userData = await Auth.currentAuthenticatedUser();
  var email = userData.attributes.email;
  return email;
}

function TokenPage({route,navigation}) {
    const [decimalCount,setDecimalCount]=useState(6);

    const [transactions,setTransactions]=useState([]);
    const [wallets,setWallets]=useState([]);
    const [chart,setChart]=useState(7);
    const [change,setChange]=useState(0);
    const [noData,setNoData]=useState(false);
    const [prices,setPrices] = useState({'labels':['loading'], datasets:[{data: [0.0002,]}]});
    const [socials,setSocials] = useState(false);
    const [chartPadding,setChartPadding]=useState(85);

    useEffect(() => {
      checkData();


    }, [route.params.item.contract])


    async function checkData() {

      if (route.params.item.price == 0.0){
        //console.log('nah bruh');
        route.params.item.price ==0;

      } else {
        getPrices(7);
        var zeros = 0;
        var num = parseFloat(route.params.item.price)
        while(num < 1){
          zeros++;
          num *= 10;

        }

        //console.log("zeros is :"+zeros);
        setDecimalCount(zeros+2);
        zeros <= 1 ? setChartPadding(55) : zeros <= 5 ? setChartPadding(75) : zeros <= 7 ? setChartPadding(90):zeros <= 10 ? setChartPadding(115): setChartPadding(140);
      }
    }





    async function getPrices(days){
      setPrices({'labels':['loading'], datasets:[{data: [0.0002,]}]});
      setChart(days);

      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/tokens/line-chart?tokenId="+route.params.item.token_id+"&days="+days, requestOptions)
      var data = await grab.json();
      //console.log(data);
      //console.log(data.datasets[0].data.length);
      if (data.datasets[0].data.length > 2){
        await setPrices(data);
        //console.log(prices);
        percentageChange(data);
      } else {

        setPrices({'labels':['noData'], datasets:[{data: [0.0002,]}]});
      }


    }

    async function checkData() {
      if (route.params.item.price == 0.0){
        //console.log('nah bruh');
        route.params.item.price ==0;

      } else {
        getPrices(7);
      }
    }

    const chartConfig = {
      paddingRight: chartPadding,

      backgroundColor: ChartColors.background,
      backgroundGradientFrom: ChartColors.gradientFrom,
      backgroundGradientTo: ChartColors.gradientTo,
      decimalPlaces: decimalCount, // optional, defaults to 2dp
      color: (opacity = 1) => ChartColors.color,
      labelColor: (opacity = 1) => ChartColors.labelColor,
      style: {
        borderRadius: 16
      },

    }

    

    async function percentageChange(data){
      let d = data.datasets[0].data;
      let oldValue =data.datasets[0].data[0];
      let newValue = data.datasets[0].data[(d.length - 1)];
      //console.log('current price: '+newValue+" old price: "+oldValue);
      let change = (newValue - oldValue)/oldValue;
      change = change * 100;
      setChange(change);
      return change;
    }

    function nFormatter(num) {
        const lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "B" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "Q" },
          { value: 1e18, symbol: "Q" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        return item ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol : "0";
      }

      const decimalCheck = (num) => {
        var zeros = 0;
        var num = parseFloat(num)
        if (num < 0.01){
        while(num < 1){
          zeros++;
          num *= 10;
  
        }
        return zeros-1
  
      } else {
        return 0
      }
  
        //console.log("zeros is :"+zeros);
        //setDecimalCount(zeros+2);
      }
  
      const priceFormat = (num,zeros) => {
  
        num = num.toFixed(18)
        num = num.toString().slice(zeros)
        num = num.slice(0,5)
        return num
  
        //console.log("zeros is :"+zeros);
        //setDecimalCount(zeros+2);
      }

    const Images = {
      "bsc":require( './../img/logos/bnb.png'),
      "eth":require( './../img/logos/eth.png'),
      "poly":require( './../img/logos/poly.png')

    }

    const explorers = {
        "bsc":"https://bscscan.com/token/"+route.params.item.contract,
        "eth":"https://etherscan.io/token/"+route.params.item.contract,
        "poly":"https://polygonscan.com/token/"+route.params.item.contract

      }

    const Chart = () => (
      <View style= {{width:'100%',height:220,marginHorizontal:20,}}>

          <LineChart
          data={prices}
          withVerticalLabels={false}
          withVerticalLines={false}
          onDataPointClick={()=>{console.log('masoud')}}

          withDots={false}
          width={Dimensions.get("window").width}
          height={220}
          bezier
          chartConfig={(prices.size != true) ? chartConfig : chartConfig2}
          />
      </View>
    )

    const ChartLoading = () => (
      <View style= {{width:'100%',height:220,justifyContent:'center',marginHorizontal:20,}}>




       <ActivityIndicator size='large' color={(opacity = 2) => `rgba(100, 255, 255, ${opacity})`} />
      </View>
    )

    const ChartNoData = () => (
      <View style= {{width:'100%',height:220,justifyContent:'center',marginHorizontal:20,alignItems:'center'}}>
            <Text style={DarkTheme.values}>Not Enough Data</Text>

      </View>
    )

    let [fontsLoaded] = useFonts({
      Poppins_500Medium,
    });

    if (!fontsLoaded) {
      return <AppLoading />;
    }


    const Header = () => (
      <View style={DarkTheme.headerBackground}>
            <TouchableOpacity style={{position:'absolute',paddingRight:300,paddingBottom:8,justifyContent:'center',alignItems:'center'}} onPress={() =>navigation.goBack()}>
                        <Text style={DarkTheme.values}><FontAwesome5 name={'angle-double-left'} style={{fontSize:25,color:Icon_color}} /></Text>
            </TouchableOpacity>
              <Text style={DarkTheme.headerText}>{route.params.item.name.toUpperCase()}</Text>
          </View>
    )





    const Socials = () => (

            <View style={DarkTheme.infoContainer}>

                {route.params.item.project_info.twitter ?
                <View style={DarkTheme.infoColumn}>
                    <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>Linking.openURL(route.params.item.project_info.twitter)}>
                        <Text style={DarkTheme.values}><FontAwesome5 name={'twitter'} style={{fontSize:15}} />  Twitter</Text>
                    </TouchableOpacity>
                </View>
                : null
                }
                {route.params.item.project_info.discord ?
                <View style={DarkTheme.infoColumn}>
                  <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>Linking.openURL(route.params.item.project_info.discord)}>
                      <Text style={DarkTheme.values}><FontAwesome5 name={'discord'} style={{fontSize:18}} />  Discord</Text>
                  </TouchableOpacity>
                </View>
                : null
                }
                {route.params.item.project_info.telegram ?
                <View style={DarkTheme.infoColumn}>
                    <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>Linking.openURL(route.params.item.project_info.telegram)}>
                        <Text style={DarkTheme.values}><FontAwesome5 name={'telegram'} style={{fontSize:15}} />  Telegram</Text>
                    </TouchableOpacity>
                </View>
                : null }




            </View>
    )



    return (






      <View style={DarkTheme.container}>
        <Header />
        <Menu props={{"navigation": navigation}}/>
        <ScrollView style={{width:'100%'}}>

        <View style={{flexDirection:'row', height: 100,width:'100%',marginVertical:1,justifyContent:'center',alignItems:'center'}}>
          <View style={{flexDirection:'row', justifyContent:'center',alignItems:'center', height: 100,width:'25%'}}>
              <Image
              source={{uri: route.params.item.project_info.logo_url}}
              style={{width: 70,
                height: 70,

                resizeMode:'contain',

                }}
              ></Image>

          </View>

          <View style={{flexDirection:'column',justifyContent:'center', height: 100,width:'45%'}}>
              <Text style={DarkTheme.name}>{route.params.item.symbol.toUpperCase()}</Text>

              <View style={{width:'100%',flexDirection:'row'}}>
                        <Text style={DarkTheme.price}>{(parseFloat(route.params.item.usd_price)*parseFloat(route.params.currency.currency_rate)) < 0.01 ? "0.0" : null}</Text>
                        <Text style={[DarkTheme.price,{paddingTop:9,fontSize:10,marginHorizontal:1}]}>{(parseFloat(route.params.item.usd_price)*parseFloat(route.params.currency.currency_rate)) < 0.01 ? decimalCheck(parseFloat(route.params.item.usd_price)*parseFloat(route.params.currency.currency_rate)) : null}</Text>
                        <Text style={DarkTheme.price}>{(parseFloat(route.params.item.usd_price)*parseFloat(route.params.currency.currency_rate)) < 0.01 ? priceFormat(parseFloat(route.params.item.usd_price)*parseFloat(route.params.currency.currency_rate),decimalCheck(parseFloat(route.params.item.usd_price)*parseFloat(route.params.currency.currency_rate))+2): route.params.currency.currency_prefix+(parseFloat(route.params.item.usd_price)*parseFloat(route.params.currency.currency_rate)).toFixed(5)} </Text>
                    </View>




          </View>

          <View style={{flexDirection:'column',justifyContent:'center',alignItems:'center', height: 100,width:'30%',}}>
              <Text style={(change >= 0) ? DarkTheme.percentageChangePositive : DarkTheme.percentageChangeNegative}>{change.toFixed(2)}%</Text>

            </View>


        </View>



        <View style={DarkTheme.dataContainer}>

          <View style={DarkTheme.dataColumn}>
            <TouchableOpacity style={{justifyContent:'center', alignItems:'center',}}onPress={() => {Clipboard.setString(route.params.item.contract)}}>
              <Text style={DarkTheme.labels}>Contract</Text>
              <Text style={DarkTheme.values}>{route.params.item.contract.slice(0,4)+'...'+route.params.item.contract.slice(38)} <FontAwesome5 name={'copy'} style={{fontSize:15}} /></Text>
            </TouchableOpacity>
          </View>

          <View style={DarkTheme.dataColumn}>
          <Image
                                source={(route.params.item.chain == 56) ? Images.bsc : (route.params.item.chain == 1) ? Images.eth : Images.poly}
                                style={DarkTheme.imgStyle}
                                ></Image>
          </View>
          <View style={DarkTheme.dataColumn}>

          <Text style={DarkTheme.labels}>Total Supply</Text>
            <Text style={DarkTheme.values}>{nFormatter(route.params.item.total_supply)}</Text>


          </View>


        </View>


        {(prices.labels[0] == 'loading') ? <ChartLoading /> : (prices.labels[0] == 'noData') ? <ChartNoData /> : <Chart />}



        <View style={DarkTheme.rangeContainer}>
          <View style={DarkTheme.dataColumn}>
            <TouchableOpacity style={(chart == 90) ? DarkTheme.activeChart : DarkTheme.inactiveChart} onPress={() => {getPrices(90)}}>
              <Text  style={(chart == 90) ? DarkTheme.activeRange : DarkTheme.inactiveRange}>90d</Text>
            </TouchableOpacity>
          </View>

          <View style={DarkTheme.dataColumn}>
            <TouchableOpacity style={(chart == 60) ? DarkTheme.activeChart : DarkTheme.inactiveChart} onPress={() => {getPrices(60)}}>
              <Text  style={(chart == 60) ? DarkTheme.activeRange : DarkTheme.inactiveRange}>60d</Text>
            </TouchableOpacity>
          </View>

          <View style={DarkTheme.dataColumn}>
          <TouchableOpacity style={(chart == 30) ? DarkTheme.activeChart : DarkTheme.inactiveChart} onPress={() => {getPrices(30)}}>
              <Text  style={(chart == 30) ? DarkTheme.activeRange : DarkTheme.inactiveRange}>30d</Text>
            </TouchableOpacity>
          </View>

          <View style={DarkTheme.dataColumn}>
            <TouchableOpacity style={(chart == 7) ? DarkTheme.activeChart : DarkTheme.inactiveChart} onPress={() => {getPrices(7)}}>
              <Text  style={(chart == 7) ? DarkTheme.activeRange : DarkTheme.inactiveRange}>7d</Text>
            </TouchableOpacity>
          </View>

          </View>









        <View style={DarkTheme.aboutContainer}>
            <Text style={DarkTheme.infoTitle}>{route.params.item.name.toUpperCase()} Info</Text>

            <View style={DarkTheme.infoContainer}>


                <View style={DarkTheme.infoColumn}>
                    <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>Linking.openURL(route.params.item.project_info.website)}>
                        <Text style={DarkTheme.values}><FontAwesome5 name={'link'} style={{fontSize:15}} />  Website</Text>
                    </TouchableOpacity>
                </View>

                <View style={DarkTheme.infoColumn}>
                    <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>Linking.openURL((route.params.item.chain == 56) ? explorers.bsc : (route.params.item.chain == 1) ? explorers.eth : explorers.poly)}>
                        <Text style={DarkTheme.values}><FontAwesome5 name={'wpexplorer'} style={{fontSize:18}} />  Explorer</Text>
                    </TouchableOpacity>
                </View>
                <View style={DarkTheme.infoColumn}>
                    <TouchableOpacity style={{justifyContent:'center',alignItems:'center'}} onPress={() =>{(!socials)? setSocials(true): setSocials(false)}}>
                        <Text style={DarkTheme.values}><FontAwesome5 name={(socials) ? 'caret-up' : 'caret-down'} style={{fontSize:15}} />  Socials</Text>
                    </TouchableOpacity>
                </View>




            </View>
            {(socials) ? <Socials /> : null}

                    <Text style={DarkTheme.descriptionTitle}>About </Text>
                    <Text style={DarkTheme.description}>{route.params.item.project_info.description}</Text>

        </View>








        </ScrollView>

      </View>


    );
  }

  export default TokenPage;
