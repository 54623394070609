const Theme = {
        background: "#F7F7F7",
        header: "#EBEBEB",
        header_text_color: "#0D111C",
        font: "Poppins_500Medium",
        row_background_color: "#FDFDFD",
        other_text_color: "#0D111C",
        native_label_color: "#B7D185",
        border_color: "#FDFDFD",
        labels:"#757575",
        chart_line: `#B7D185`,
        chart_labels: `#0D111C`,
        price_color: "#757575",
        icon_color: "#757575",
        button_color: "#B7D185",
        button_disabled: "rgba(183,209,133,0.7)"

}

export default Theme;