import React, {useState,useEffect} from 'react';
import { ScrollView,Image,ActivityIndicator,ImageBackground, TouchableOpacity,FlatList,StyleSheet,Text,TextInput, View, Button } from 'react-native';
import Amplify, { Auth,} from 'aws-amplify';
import {styles} from './../styles';
import { useFonts, Poppins_500Medium} from '@expo-google-fonts/poppins'
import AppLoading from 'expo-app-loading';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';

import Theme from '../theme/theme';

import Animated, {
    FadeInUp,
    FadeInLeft,
    ZoomOut,
    ZoomOutDown,
    FadeInRight,
  } from 'react-native-reanimated';


function LynxAuth({ navigation,route }) {
    
    const [email,setEmail] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState('signIn');
    
    
    function Confirm() {
        
            const [username,setUsername] = useState('');
            const [password,setPassword] = useState('');
            const [code,setCode] = useState('');
            const [isLoading, setIsLoading] = useState(false);
            const [error, setError] = useState(null);
                 

            async function confirmSignUp() {
                try {
                //console.log(email)
                username == '' ? await Auth.confirmSignUp(email, code) : await Auth.confirmSignUp(username, code);
                //setUsername('');
                //setPassword('');
                //signIn();
                setAction('signIn');

                } catch (error) {
                    setError(error);
                    console.log('error confirming sign up', error);
                }
            }
            async function signIn() {
                setIsLoading(true);
                //console.log(username,password);
                try {
                    const user = await Auth.signIn(username, password);
                    
                } catch (error) {
                    setError(error);
                    console.log('error signing in', error);
                }
                setIsLoading(false);
            }
                
        
            const AuthError = () => 
            ( 
                <View style={{justifyContent:'center',alignItems:'center', width:'100%'}}>
                    <Text style={localStyles.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/> {error.message}</Text>
    
                </View>
                
            )
            
           
        
        return (
            
            <View
                    style={localStyles.container}
                >
                    
                <View style={{marginTop:'25%'}}>
                        <Image 
                        source={require( './../img/blue-horizonta.png')}
                        style={{width: 300,
                            height: 150,
                            marginLeft:-30,
                            resizeMode:'contain',

                        }}
                        ></Image>

                </View>
                
                <Animated.View style={localStyles.form} entering={FadeInUp.duration(800)} >
                    
                        <Text style={localStyles.title}>Email Confirmation</Text>
                        
                        <View style={{flexDirection:'row',width:'100%',height:50,borderRadius:10,justifyContent:'center',alignItems:'center',backgroundColor:'#ffff'}}>
                            
                            <FontAwesome5 name={'at'} style={{fontSize:15}}/>
                            <TextInput 
                            style={localStyles.textInput}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            value={email ? email : null}
                            placeholder="email"
                            onChangeText={userName => setUsername(userName.trim())} />
    
    
                        </View>
                        
                        <View style={{flexDirection:'row',width:'100%',height:50,borderRadius:10,marginVertical:15,justifyContent:'center',alignItems:'center',backgroundColor:'#ffff'}}>
                            <FontAwesome5 name={'hashtag'} style={{fontSize:15}}/>
                            <TextInput
                            style={localStyles.textInput}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            
                            placeholder="code"
                            onChangeText={pass => setCode(pass.trim())}
                            />
    
                        </View>
                        {(error) ? <AuthError />: null}
                        <View style={{flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
                            <TouchableOpacity
                                
                                onPress={()=> setAction('signIn')}
                                >
                            <Text style={localStyles.t}>Back to Sign In</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                
                                onPress={()=> setAction('signUp')}
                                >
                                <Text style={localStyles.t}>Create Account</Text>
                            </TouchableOpacity>
                            
        
                        </View>
                        
                        <TouchableOpacity
                            style={localStyles.button}
                            onPress={confirmSignUp}
                            
                            >
                            {isLoading && <ActivityIndicator size="small" color={Theme.other_text_color} />}
                            <Text style={localStyles.buttonText}>Confirm</Text>
                        </TouchableOpacity>
                    
                </Animated.View>
    

                
            </View>
            
      
        )
      }

      function Password() {
        
        const [username,setUsername] = useState('');
        const [password,setPassword] = useState('');
        const [code,setCode] = useState('');
        const [emailSent,setEmailSent] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [error, setError] = useState(null);
             
        async function sendEmail() {
            try {
                // Send confirmation code to user's email
                await Auth.forgotPassword(username);
                setEmailSent(true);
                
            } catch (error) {
                setError(error);
                console.log('error signing up:', error);
            }
        }
        async function resetPassword() {
            try {
                // Collect confirmation code and new password, then
                res = await Auth.forgotPasswordSubmit(username, code, password)
                setAction('signIn');
                
            } catch (error) {
                setError(error);
                console.log('error signing up:', error);
            }
        }

        const AuthError = () => 
        ( 
            <View style={{justifyContent:'center',alignItems:'center', width:'100%'}}>
                <Text style={localStyles.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/> {error.message}</Text>

            </View>
            
        )
        
            
    
          
        
       
    
    return (
        
        <View
                
                style={localStyles.container}
            >
            <View style={{marginTop:'25%'}}>
                        <Image 
                        source={require( './../img/blue-horizonta.png')}
                        style={{width: 300,
                            height: 150,
                            marginLeft:-30,
                            resizeMode:'contain',

                        }}
                        ></Image>

                </View>
            
            <Animated.View style={localStyles.form} entering={FadeInUp.duration(800)} >
                
                    <Text style={localStyles.title}>Password Reset</Text>
                    
                    <View style={{flexDirection:'row',width:'100%',height:50,borderRadius:10,justifyContent:'center',marginBottom:15, alignItems:'center',backgroundColor:'#ffff'}}>
                        
                        <FontAwesome5 name={'at'} style={{fontSize:15}}/>
                        <TextInput 
                        style={localStyles.textInput}
                        autoCapitalize={'none'}
                        autoCorrect={false}
                        value={null}
                        placeholder="email"
                        onChangeText={userName => setUsername(userName.trim())} />


                    </View>
                    
                    {emailSent ?<View style={{flexDirection:'row',width:'100%',height:50,borderRadius:10,marginBottom:15,justifyContent:'center',alignItems:'center',backgroundColor:'#ffff'}}>
                        <FontAwesome5 name={'hashtag'} style={{fontSize:15}}/>
                        <TextInput
                        style={localStyles.textInput}
                        autoCapitalize={'none'}
                        autoCorrect={false}
                        
                        placeholder="code"
                        onChangeText={pass => setCode(pass.trim())}
                        />

                    </View>:null}
                    {emailSent ?<View style={{flexDirection:'row',width:'100%',height:50,borderRadius:10,marginBottom:15,justifyContent:'center',alignItems:'center',backgroundColor:'#ffff'}}>
                        <FontAwesome5 name={'lock'} style={{fontSize:15}}/>
                        <TextInput
                        style={localStyles.textInput}
                        autoCapitalize={'none'}
                        autoCorrect={false}
                        secureTextEntry={true}
                        placeholder="new password"
                        onChangeText={pass => setPassword(pass.trim())}
                        />

                    </View>:null}
                    
                    <View style={{flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
                        <TouchableOpacity
                            
                            onPress={()=> setAction('signIn')}
                            >
                        <Text style={localStyles.t}>Back to Sign In</Text>
                        </TouchableOpacity>
                        <Text style={localStyles.t}>Create Account</Text>
    
                    </View>
                    {(error) ? <AuthError />: null}
                    <TouchableOpacity
                        style={localStyles.button}
                        onPress={(emailSent)? resetPassword : sendEmail}
                        
                        >
                        {isLoading && <ActivityIndicator size="small" color={Theme.other_text_color} />}
                        <Text style={localStyles.buttonText}>{(emailSent) ? "Reset Password" : "Send Email"}</Text>
                    </TouchableOpacity>
                
            </Animated.View>


            
        </View>
        
  
    )
  }

    function SignUp() {
        const [username,setUsername] = useState('');
        const [password,setPassword] = useState('');
        const [isLoading, setIsLoading] = useState(false);
        const [error, setError] = useState(null);

            
        const AuthError = () => 
        ( 
            <View style={{justifyContent:'center',alignItems:'center', width:'100%'}}>
                <Text style={localStyles.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/> {error.message}</Text>

            </View>
            
        )

            
        async function signUp() {
            
            try {
                const { user } = await Auth.signUp({
                    username,
                    password
                });
                //console.log(user);
                setEmail(username);
                setAction('confirm');
            } catch (error) {
                setError(error);
                console.log('error signing up:', error);
            }
        }

        return (
            <View
                    style={localStyles.container}
                >
            
            <View style={{marginTop:'25%'}}>
                        <Image 
                        source={require( './../img/blue-horizonta.png')}
                        style={{width: 300,
                            height: 150,
                            marginLeft:-30,
                            resizeMode:'contain',

                        }}
                        ></Image>

                </View>
                
            <Animated.View style={localStyles.form} entering={FadeInLeft.duration(800)}  >

                        
                    
                        <Text style={localStyles.title}>Create Account</Text>
                        
                        
                        <View style={{flexDirection:'row',width:'100%',height:50,borderRadius:10,justifyContent:'center',alignItems:'center',backgroundColor:'#ffff'}}>
                            
                            <FontAwesome5 name={'at'} style={{fontSize:15}}/>
                            <TextInput 
                            style={localStyles.textInput}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            value={null}
                            placeholder="email"
                            onChangeText={userName => setUsername(userName.trim())} />
    
    
                        </View>
                        
                        <View style={{flexDirection:'row',width:'100%',height:50,borderRadius:10,marginVertical:15,justifyContent:'center',alignItems:'center',backgroundColor:'#ffff'}}>
                            <FontAwesome5 name={'lock'} style={{fontSize:15}}/>
                            <TextInput
                            style={localStyles.textInput}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            secureTextEntry={true}
                            placeholder="password"
                            onChangeText={pass => setPassword(pass.trim())}
                            />
    
                        </View>
                        {(error) ? <AuthError />: null}
                        <View style={{flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
                        <TouchableOpacity
                                
                                onPress={()=> setAction('signIn')}
                                >
                            <Text style={localStyles.t}>Back to Sign In</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                                
                                onPress={()=> setAction('confirm')}
                                >
                                <Text style={localStyles.t}>Email Verification</Text>
                        </TouchableOpacity>
                            
        
                        </View>
                        
                        <TouchableOpacity
                            style={username.length < 1 || password.length < 8 ? localStyles.buttonDisabled : localStyles.button}
                            onPress={signUp}
                            disabled={username.length < 1 || password.length < 8 ? true : false}
                            >
                            {isLoading && <ActivityIndicator size="small" color={Theme.other_text_color} />}
                            <Text style={username.length < 1 || password.length < 8 ? localStyles.buttonTextDisabled : localStyles.buttonText}>Let's Go!</Text>
                        </TouchableOpacity>
                    
                </Animated.View>
    
                
                
            
            </View>
            
      
        )
      }

    function SignIn() {
        const [username,setUsername] = useState('');
        const [password,setPassword] = useState('');
        const [isLoading, setIsLoading] = useState(false);
        const [error, setError] = useState(null);      

            
    async function signIn() {
        setIsLoading(true);
        //console.log(username,password);
        try {
            const user = await Auth.signIn(username, password);
            
        } catch (error) {
            console.log('error signing in', error);
            setError(error);
        }
        setIsLoading(false);
    }
    const AuthError = () => 
        ( 
            <View style={{justifyContent:'center',alignItems:'center', width:'100%'}}>
                <Text style={localStyles.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/> {error.message}</Text>

            </View>
            
        )

        
        return (
            
            <View
                    style={localStyles.container}
                >
                <View style={{marginTop:'25%'}}>
                        <Image 
                        source={require( './../img/blue-horizonta.png')}
                        style={{width: 300,
                            height: 150,
                            marginLeft:-30,
                            resizeMode:'contain',

                        }}
                        ></Image>

                </View>
                
                
                <Animated.View style={localStyles.form} entering={FadeInRight.duration(800)} >
                    
                    
                        <Text style={localStyles.title}>Sign In </Text>
                        
                        <View style={{flexDirection:'row',width:'100%',height:50,borderRadius:10,justifyContent:'center',alignItems:'center',backgroundColor:'#ffff'}}>
                            
                            <FontAwesome5 name={'at'} style={{fontSize:15}}/>
                            <TextInput 
                            style={localStyles.textInput}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            value={null}
                            placeholder="email"
                            onChangeText={userName => setUsername(userName.trim())} />
    
    
                        </View>
                        
                        <View style={{flexDirection:'row',width:'100%',height:50,borderRadius:10,marginVertical:15,justifyContent:'center',alignItems:'center',backgroundColor:'#ffff'}}>
                            <FontAwesome5 name={'lock'} style={{fontSize:15}}/>
                            <TextInput
                            style={localStyles.textInput}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            secureTextEntry={true}
                            placeholder="password"
                            onChangeText={pass => setPassword(pass.trim())}
                            />
    
                        </View>
                        {(error) ? <AuthError />: null}
                        <Animated.View style={{flexDirection:'row',width:'100%',justifyContent:'space-between'}} entering={FadeInUp}>
                            <TouchableOpacity
                                
                                onPress={()=> setAction('signUp')}
                                >
                            <Text style={localStyles.t}>Create Account</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                
                                onPress={()=> setAction('forgot')}
                                >
                            <Text style={localStyles.t}>Forgot Password</Text>
                            </TouchableOpacity>
                        </Animated.View>
                        
                        <TouchableOpacity
                            style={username.length < 1 || password.length < 1 ? localStyles.buttonDisabled : localStyles.button}
                            onPress={signIn}
                            disabled={username.length < 1 || password.length < 1 ? true : false}
                            >
                            {isLoading && <ActivityIndicator size="small" color={Theme.other_text_color} />}
                            <Text style={username.length < 1 || password.length < 1 ? localStyles.buttonTextDisabled : localStyles.buttonText}>Sign In</Text>
                        </TouchableOpacity>
                    
                </Animated.View>
    
                
                
            </View>
            
      
        )
      }
            
    let [fontsLoaded] = useFonts({
        Poppins_500Medium

      });
    
      if (!fontsLoaded) {
        return <AppLoading />;
      }       

    
    async function signIn() {
        setIsLoading(true);
        //console.log(username,password);
        try {
            const user = await Auth.signIn(username, password);
            
        } catch (error) {
            console.log('error signing in', error);
        }
        setIsLoading(false);
    }

    async function signUp() {
        try {
            const { user } = await Auth.signUp({
                username,
                password
            });
            console.log(user);
            navigation.navigate('Confirm',{email: username,pass: password})
        } catch (error) {
            console.log('error signing up:', error);
        }
    }
    async function confirmSignUp() {
        try {
          await Auth.confirmSignUp(username, code);
          setUsername('');
          setPassword('');
          signIn();
        } catch (error) {
            console.log('error confirming sign up', error);
        }
    }
          
        
    return (
        <View style = {{width:'100%',height:'100%'}}>
            
            <View style={{width:'100%',height:"100%",alignItems:"center",backgroundColor: Theme.background}}>
            {(action =="signIn")? <SignIn /> : (action =="signUp") ? <SignUp /> : (action =="confirm") ? <Confirm /> : (action =="forgot") ? <Password /> : null}

            </View>
            
        </View>
    )
  }

  export default LynxAuth;

const localStyles = StyleSheet.create({
    container: {
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems:'center',
        height:'100%',
      },
    form: {
        alignItems:'center',
        
        width:320,
        paddingHorizontal:10,
        marginTop:0,
    },
    t: {
        fontFamily: Theme.font,
        color:'gray',
        fontSize:14,
        marginBottom: 15,
        paddingHorizontal:12,
    },
    button: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        marginTop:15,
        alignItems: "center",
        backgroundColor: Theme.button_color,
        
        color:Theme.other_text_color,
        width: 200,
        height: 50,
        fontFamily: Theme.font,
        alignSelf:'center',
        borderRadius:10,
    },
    buttonDisabled: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        marginTop:15,
        alignItems: "center",
        backgroundColor: Theme.button_disabled,
        
        color:Theme.other_text_color,
        width: 200,
        height: 50,
        fontFamily: Theme.font,
        alignSelf:'center',
        borderRadius:10,
    },
    title: {
        fontFamily: Theme.font,
        color:Theme.other_text_color,
        fontSize:25,
        marginBottom: 25,
    },
    label: {
        fontFamily: Theme.font,
        color:Theme.other_text_color,
        fontSize:15,
        marginBottom: 10,
        paddingLeft:0,
        alignSelf:'flex-start',
    },

    textInput: {
        width:'80%',
        paddingLeft:14,
        fontFamily: Theme.font,
        fontSize:15,
        
        },

    
    invalid: {
        marginBottom:10,
        color:'red',
        fontFamily: Theme.font,
        fontSize:15,
        },

    buttonText: {
        fontFamily: Theme.font,
        color:Theme.other_text_color,
        fontSize:15,
    },
    buttonTextDisabled: {
        fontFamily: Theme.font,
        color:'rgba(255,255,255,.3)',
        fontSize:15,
    }
    

  });