import { StyleSheet} from 'react-native';





const LightTheme = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      
    },
    headerBackground: {
        
      backgroundColor: '#3F4ED1',
      alignItems: 'center',
      justifyContent:'flex-end',
      height:90,
      width:'100%',},
  
    headerText: {
        fontFamily: 'Poppins_500Medium',
        color:'#fff',
        fontSize:25,
        paddingBottom:5,
      },
    addButton: {
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:'#49C07D',
      paddingVertical:7,
      marginRight:15,
      width:110,
      borderColor:'#49C07D',
      borderWidth:1,
      borderRadius:150
    },
    addButtonText: {
      fontFamily: 'Poppins_500Medium',
      paddingRight:0,
      color: '#fff',
      fontSize:13,
      fontWeight:'bold',
    },
    rowContainer: {
      flexDirection:'row',
      backgroundColor: '#ebe8e8',
      borderRadius:10,
      borderColor:'#e1e3e1',
      height: 65,
      marginVertical:3,
      marginHorizontal:12,
      paddingHorizontal:10,
    },
  
    swipeStyle: {
      justifyContent:'center',
      alignItems:'center',
      marginVertical:3,
      marginLeft:-10,
      marginRight:12,
      width:'20%',
      height:65,
      backgroundColor: 'red',
      borderRadius:10,
    },

    hideIcon: {
      fontFamily: 'Poppins_500Medium',
      fontSize:25,
      color:'#fff',
    },
    
    walletBorder: {
      width: '100%',
      
    },

   wallets: {
    fontFamily: 'Poppins_500Medium',
      paddingRight:0,
      color: '#000',
      fontSize:15,
      fontWeight:'bold',
   },
   walletName: {
      fontFamily: 'Poppins_500Medium',
      paddingRight:0,
      color: '#000',
      fontSize:15,
      fontWeight:'bold',
   },
   labels: {
    color:'#9c9c9c',
    fontFamily: 'Poppins_500Medium',
    fontSize:12,
  },
   addWalletContainer: {
    flexDirection:'row',
    justifyContent: 'center',
    alignItems:'center',
    backgroundColor:'#e1e3e1',
    height:50,
    width:'100%'
    
  },
    addWalletSubContainer: {
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'row',
        height:'100%',
        width: '75%',
        
    },
    swipeLeftIcon: {
      fontSize:15,
      color:'#e1e3e1'
    },
    addWalletSubContainer1: {
      justifyContent:'center',
      alignItems:'center',
      marginRight:30,
      height:'100%',
      width: '25%',
      
  },
    addIcon: {
        fontSize:15,
        color:'white',},

    walletsAdd: {
      fontFamily: 'Poppins_500Medium',
      marginLeft:245,
      paddingTop:10,
      paddingRight:30,
      color: '#fff',
      fontSize:15,
      fontWeight:'bold',
    },

    //ADD Wallet Screen
    
    containeraddWallet: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
           
       },

    headerBackgroundaddWallet: {
          
        backgroundColor: '#3F4ED1',
        alignItems: 'center',
        justifyContent:'flex-end',
        height:90,
        width:'100%',},
      
     headerTextaddWallet: {
        fontFamily: 'Poppins_500Medium',
        color:'#fff',
        fontSize:25,
        paddingBottom:5,
       },

      form: {
          alignItems:'center',
          padding:10,
          marginTop:15
      
      },
      t: {
          fontFamily: 'Poppins_500Medium',
          color:'#9c9c9c',
          fontSize:12,
          marginBottom: 15,
          paddingHorizontal:12,
      },
      button: {
          flexDirection: "row",
          justifyContent: "space-evenly",
          marginTop:15,
          alignItems: "center",
          backgroundColor: "#3F4ED1",
          
          color:'#fff',
          width: 200,
          height: 50,
          fontFamily: 'Poppins_500Medium',
          alignSelf:'center',
          borderRadius:10,
      },
      title: {
          fontFamily: 'Poppins_500Medium',
          color:'#000',
          fontSize:20,
          marginBottom: 25,
      },
      label: {
          fontFamily: 'Poppins_500Medium',
          color:'#000',
          fontSize:15,
          marginBottom: 10,
      },
  
      textInput: {
          width:300,
          height:50,
          paddingHorizontal:10,
          marginBottom:25,
          backgroundColor:'#9c9c9c9c',
          fontFamily: 'Poppins_500Medium',
          fontSize:15,
          borderRadius:10,
          },
  
      
      invalid: {
          marginTop:-15,
          marginBottom:10,
          paddingLeft:0,
          color:'red',
          fontFamily: 'Poppins_500Medium',
          fontSize:15,
          
          
          },
  
      buttonText: {
          fontFamily: 'Poppins_500Medium',
          color:'#fff',
          fontSize:15,
          
      },
      
  
    });

  export { LightTheme };