import { StyleSheet} from 'react-native';

const LightTheme = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',  
      },

      headerBackground: {
        backgroundColor: '#3F4ED1',
        alignItems: 'center',
        justifyContent:'flex-end',
        height:90,
        width:'100%',
      },
    
      headerText: {
          fontFamily: 'Poppins_500Medium',
          color:'#fff',
          fontSize:25,
          paddingBottom:5,
        },
    
    title: {
        fontFamily: 'Poppins_500Medium',
        color:'#000',
        fontSize:20,
        marginTop:150,
        marginBottom: 25,
    },

    rowSymbol: {
        fontFamily: 'Poppins_500Medium',
        paddingLeft:15,
        color: '#000',
        fontSize:15,
        fontWeight:'bold',
      },

      rowPrice: {
        fontFamily: 'Poppins_500Medium',
        color: '#000',
        fontSize:10,
      },

      rowValue: {
        fontFamily: 'Poppins_500Medium',
        color: '#000',
        fontSize:12,
      },

      rowContainer: {
        flexDirection:'row',
        alignItems:'center',
        backgroundColor: '#edebeb',
        borderWidth: 2,
        borderRadius:10,
        borderColor:'#9c9c9c',
        height: 65,
        marginVertical:3,
        marginHorizontal:12,
        paddingHorizontal:10,
      },

      arrow: {
        color:'#000',
        fontFamily: 'Poppins_500Medium',
        fontSize:15,
        justifyContent:'flex-end',
      },
    
      //Hidden Tokens Screen
      containerHiddenTokens: {
        flex: 1,
        backgroundColor: '#f6f6f6',
        alignItems: 'center',
        justifyContent: 'center',
      },
      
      headerBackgroundHiddenTokens: {
        backgroundColor: '#3F4ED1',
        alignItems: 'center',
        justifyContent:'flex-end',
        height:90,
        width:'100%',
      },
    
      headerTextHiddenTokens: {
          fontFamily: 'Poppins_500Medium',
          color:'#fff',
          fontSize:25,
          paddingBottom:5,
        },
    
  
      rowContainerHiddenTokens: {
        flexDirection:'row',
        backgroundColor: '#fff',
        borderRadius:10,
        borderColor:'#f6f6f6',
        height: 65,
        marginVertical:3,
        marginHorizontal:12,
        paddingHorizontal:10,
      },
    
      swipeStyle: {
        justifyContent:'center',
        alignItems:'center',
        marginVertical:3,
        marginLeft:-10,
        marginRight:12,
        width:'20%',
        height:65,
        backgroundColor: '#49C07D',
        borderRadius:10,
      },

      hideIcon: {
        fontFamily: 'Poppins_500Medium',
        fontSize:25,
        color:'#fff'
      },
    
      wallets: {
        fontFamily: 'Poppins_500Medium',
        paddingRight:0,
        color: '#000',
        fontSize:15,
        fontWeight:'bold',
     },

     walletName: {
        fontFamily: 'Poppins_500Medium',
        paddingRight:0,
        color: '#000',
        fontSize:12,
        fontWeight:'bold',
     },
     labels: {
        color:'#9c9c9c',
        fontFamily: 'Poppins_500Medium',
        fontSize:12,
      },

     addWalletContainer: {
        flexDirection:'row',
        justifyContent: 'center',
        alignItems:'center',
        backgroundColor:'#f6f6f6',
        height:50,
        width:'100%'
      },

      addWalletSubContainer: {
        justifyContent:'center',
        alignItems:'center',
        height:'100%',
        width: '33%',
      },
      
      swipeLeftIcon: {
        fontSize:15,
          color:'#9c9c9c'
        
      },
      addIcon: {
        fontSize:20,
        color:'white',
      },
  
      walletsAdd: {
        fontFamily: 'Poppins_500Medium',
        marginLeft:245,
        paddingTop:10, 
        paddingRight:30,
        color: '#000',
        fontSize:15,
        fontWeight:'bold',    
      },

      imgStyle: {
        width: 40,
        height: 40,
        marginTop:5,
      },

  });

  export { LightTheme };