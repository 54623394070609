import React, {useState,useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { Animated,ActivityIndicator,Dimensions, View, Button } from 'react-native';
import Amplify, { Auth,Hub} from 'aws-amplify';
import config from './src/aws-exports';
import { withAuthenticator,AmplifyTheme } from 'aws-amplify-react-native'
import { getFocusedRouteNameFromRoute, NavigationContainer, DarkTheme} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {Swipeable,GestureHandlerRootView } from 'react-native-gesture-handler';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useFonts, Poppins_500Medium } from '@expo-google-fonts/poppins';
import AppLoading from 'expo-app-loading';

import AddWallet from './src/wallets/add_wallet';
import ScanWallet from './src/wallets/scan_wallet';
import HomeScreen from './src/portfolio/portfolio';
import TokenDetails from './src/portfolio/token_details'
import WalletsScreen from './src/wallets/wallet';
import Markets from './src/markets/markets';
import SettingsScreen from './src/settings/settings';
import HiddenTokens from './src/settings/hidden_tokens';
import LynxAuth from './src/authentication/sign_in';
import TokenPage from './src/markets/tokenPage';
import Transactions from './src/portfolio/transactions';
import MarketsContentLoader from './src/markets/marketsContentLoader';
import PortfolioContentLoader from './src/portfolio/portfolioContentLoader';
import Currency from './src/settings/currency';
import AddToken from './src/portfolio/addManualToken';


import Theme from './src/theme/theme';


Amplify.configure(config);


const window = Dimensions.get("window");
const screen = Dimensions.get("screen");



function App({navigation}) {
  const [user, setUser] = useState('initial');
  const [dimensions, setDimensions] = useState({ window, screen });

  
  const getUser = async() => {
    
    try{
      var userData = await Auth.currentAuthenticatedUser({bypassCache:true});
      setUser(userData);
    } catch (e) {
      setUser('noUser');
    }
    
  }

  useEffect(() => {
      
      getUser();
      const subscription = Dimensions.addEventListener(
        "change",
        ({ window, screen }) => {
          setDimensions({ window, screen });
          console.log(dimensions)
          
        }
      );
      
  }, [])

  useEffect(() => {
    const listener = (data) => {
      if(data.payload.event === 'signIn' || data.payload.event === 'signOut'){
        getUser();
      }
    }
    Hub.listen('auth',listener);
    return () => Hub.remove('auth',listener);
}, [])

  let [fontsLoaded] = useFonts({
    Poppins_500Medium,
  });

  const Loading = () => (
        <View style={{justifyContent:'center',alignItems:'center',width:'100%',height:'100%',backgroundColor:"#121212"}}>
            <ActivityIndicator size='large' color={(opacity = 2) => `rgba(100, 255, 255, ${opacity})`} />
        </View>
  )

  if (!fontsLoaded) {
    return <Loading />
  }

  const mytheme={...DarkTheme,colors:{...DarkTheme.colors,background:Theme.background}}
  
  return (
    
    <NavigationContainer >
      <StatusBar style="light" backgroundColor={Theme.background} />
      
      {(user =='initial')? <Loading /> : (user=='noUser') ? 
      <HomeStack.Navigator >
      <HomeStack.Screen 
      
      name="Sign In" 
      component={LynxAuth} 
      options={{
        headerShown: false, }}  />
     
      
    </HomeStack.Navigator >
      : 
      <Tab.Navigator theme={DarkTheme}>
      
        <Tab.Screen name="Portfolio" component={HomeStackScreen} options={{headerShown: false,tabBarStyle:{ display:dimensions.window.width > 700 ? 'none' : 'flex' },headerShown: false,tabBarStyle:{backgroundColor:Theme.background, borderTopWidth: 0},tabBarActiveTintColor:Theme.button_color, tabBarLabelStyle:{fontFamily: 'Poppins_500Medium', },tabBarIcon: ({size,focused}) => (<FontAwesome5 name={'coins'} color={(focused) ?Theme.button_color:'#9c9c9c'} size={size}/>),tabBarStyle:{ display:dimensions.window.width > 700 ? 'none' : 'flex' } }}/>
        <Tab.Screen name="Discover" component={MarketsStackScreen} options={{headerShown: false,tabBarStyle:{backgroundColor:Theme.background, borderTopWidth: 0},tabBarActiveTintColor:Theme.button_color, tabBarLabelStyle:{fontFamily: 'Poppins_500Medium', }, tabBarIcon: ({size,focused}) => (<FontAwesome5 name={'chart-line'} color={(focused) ?Theme.button_color:'#9c9c9c'} size={size}/>),tabBarStyle:{display:dimensions.window.width > 700 ? 'none' : 'flex' }}}/>
        <Tab.Screen name="Wallets" component={WalletScreenStack} options={{headerShown: false,tabBarStyle:{backgroundColor:Theme.background, borderTopWidth: 0}, tabBarActiveTintColor:Theme.button_color, tabBarLabelStyle:{fontFamily: 'Poppins_500Medium',}, tabBarIcon: ({size,focused}) => (<FontAwesome5 name={'wallet'} color={(focused) ?Theme.button_color:'#9c9c9c'} size={size}/>),tabBarStyle:{display:dimensions.window.width > 700 ? 'none' : 'flex' } }}/>
        <Tab.Screen name="Settings" component={SettingsStackScreen} options={{headerShown: false,tabBarStyle:{backgroundColor:Theme.background, borderTopWidth: 0},tabBarActiveTintColor:Theme.button_color, tabBarLabelStyle:{fontFamily: 'Poppins_500Medium',},tabBarIcon: ({size,focused}) => (<FontAwesome5 name={'wrench'} color={(focused) ?Theme.button_color:'#9c9c9c'} size={size}/>),tabBarStyle:{display:dimensions.window.width > 700 ? 'none' : 'flex' } }}/>
      </Tab.Navigator>
      
      }
    </NavigationContainer>
  );
  }


  

  function WalletScreenStack() {
    return (
      <HomeStack.Navigator>
        <HomeStack.Screen 
        
        name="Your Wallets" 
        component={WalletsScreen} 
        options={{
          headerShown: false,
          headerTitleAlign: "center",
          headerStyle:{
            backgroundColor:'#3F4ED1',
          },
          headerTitleStyle:{
            color: 'white',
            fontFamily: 'Poppins_500Medium',
            fontSize: 23,
            
          } }}  />
        <HomeStack.Screen name="Add Wallet" component={AddWallet} options={{
          headerShown: false,
          headerTitleAlign: "center",
          headerStyle:{
            backgroundColor:'#3F4ED1',
          },
          headerTitleStyle:{
            color: 'white',
            fontFamily: 'Poppins_500Medium',
            fontSize: 23,
          }
        }} />
        <HomeStack.Screen name="Wallet Setup" component={ScanWallet} options={{
          headerShown: false,
          headerBackVisible:false,
          headerTitleAlign: "center",
          headerStyle:{
            backgroundColor:'#3F4ED1',
          },
          headerTitleStyle:{
            color: 'white',
            fontFamily: 'Poppins_500Medium',
            fontSize: 23,
          }
        }} />
      </HomeStack.Navigator>
    );
  }


function HomeStackScreen() {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen 
      
      name="Portfolio" 
      component={HomeScreen} 
      options={{
        headerShown: false,
        headerTitleAlign: "center",
        headerStyle:{
          backgroundColor:'#3F4ED1',
        },
      headerTitleStyle:{
        color: 'white',
        fontFamily: 'Poppins_500Medium',
        fontSize: 23,
      } }}  />
      <HomeStack.Screen 
      name="Add Token" 
      component={AddToken} 
      options={{
        headerShown: false,
       }}  />

      <HomeStack.Screen name="Token Details" component={TokenDetails} options={{
          headerShown: false,
          headerTitleAlign: "center",
          headerStyle:{
            backgroundColor:'#3F4ED1',
          },
          headerTitleStyle:{
            color: 'white',
            fontFamily: 'Poppins_500Medium',
            fontSize: 23,
          } }}  />
      <HomeStack.Screen name="Transactions" component={Transactions} options={{
          headerShown: false,
          headerTitleAlign: "center",
          headerStyle:{
            backgroundColor:'#3F4ED1',
          },
          headerTitleStyle:{
            color: 'white',
            fontFamily: 'Poppins_500Medium',
            fontSize: 23,
          } }}  />
      
    </HomeStack.Navigator>
  );
}

function MarketsStackScreen() {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen 
      
      name="Markets" 
      component={Markets} 
      options={{
        headerShown: false,
        headerTitleAlign: "center",
        headerStyle:{
          backgroundColor:'#3F4ED1',
        },
      headerTitleStyle:{
        color: 'white',
        fontFamily: 'Poppins_500Medium',
        fontSize: 23,
      } }}  />
      <HomeStack.Screen name="Token Page" component={TokenPage} options={{
          headerShown: false,
          headerTitleAlign: "center",
          headerStyle:{
            backgroundColor:'#3F4ED1',
          },
          headerTitleStyle:{
            color: 'white',
            fontFamily: 'Poppins_500Medium',
            fontSize: 23,
          } }}  />
    </HomeStack.Navigator>
  );
}


function SettingsStackScreen() {
  return (
    <SettingsStack.Navigator>
      <SettingsStack.Screen 
        name="SettingsTab" 
        component={SettingsScreen}
        options={{headerShown: false}} />
      <SettingsStack.Screen 
        name="Hidden Tokens"
        component={HiddenTokens}
        options={{headerShown: false}} />
      <SettingsStack.Screen
        name="Currency"
        component={Currency}
        options={{headerShown: false}} />
    </SettingsStack.Navigator>
    
  );
}
const Tab = createBottomTabNavigator();
const HomeStack = createNativeStackNavigator();
const SettingsStack = createNativeStackNavigator();




const signUpConfig = {
  header: 'Sign Up',
  hideAllDefaults:true,
  signUpFields: [

    {
      label: 'Email',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password'
    },
  ],
}





export default App;