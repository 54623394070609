import { StyleSheet} from 'react-native';




const LightTheme = StyleSheet.create({
    container: {
      flex:2,
      backgroundColor: '#fff',
      alignItems: 'center',
      height:'100%'
    },

    headerBackground: {    
      backgroundColor: '#3F4ED1',
      alignItems: 'center',
      justifyContent:'flex-end',
      height:90,
      width:'100%',},
  
    headerText: {
        fontFamily: 'Poppins_500Medium',
        color:'#fff',
        fontSize:25,
        paddingBottom:5,
      },
      
    portfolio: {
        backgroundColor: '#f6f6f6',
        width: '95%',
        height: 150,
        borderRadius: 10,
        marginBottom:15,
    },

    portfolioTotal: {
        color:'#000',
        marginTop:5,
        marginHorizontal:15,
        fontSize: 35,
        fontFamily:'Poppins_500Medium',
    },

    portfolioText: {
        color:'#000',
        marginTop:15,
        marginHorizontal:15,
        paddingLeft:0,
        fontSize: 15,
        fontFamily:'Poppins_500Medium',
    },
    
    nativeSymbols: {
      color:'#000',
      fontWeight:'bold',
      marginHorizontal:0,
      paddingLeft:5,
      fontSize: 15,
      fontFamily:'Poppins_500Medium',
      },
    nativeValues: {
      color:'#000',
      marginTop:-2,
      marginHorizontal:0,
      paddingLeft:5,
      fontSize: 14,
      fontFamily:'Poppins_500Medium',
    },
    nativeSymbolContainer: {
      flexDirection:'column',
      width:70,
      height:75,
      marginTop:5,
      
    },
    nativeImageContainer: {
      flexDirection:'row',
      width:'33%',
      height:75,
    },
    rowSymbol: {
      fontFamily: 'Poppins_500Medium',
      paddingRight:0,
      color: '#000',
      fontSize:15,
      fontWeight:'bold',
    },
    rowPrice: {
      fontFamily: 'Poppins_500Medium',
      color: '#000',
      fontSize:10,
    },
    rowValue: {
      fontFamily: 'Poppins_500Medium',
      color: '#000',
      fontSize:12,
    },
    rowContainer: {
      flexDirection:'row',
      backgroundColor: '#ffffff',
      borderWidth: 2,
      borderRadius:10,
      borderColor:'#ffffff',
      height: 65,
      marginVertical:3,
      marginHorizontal:12,
      paddingHorizontal:10,
    },
    
    imgStyle: {
      width: 40,
      height: 40,
      marginTop:5,
      
    },
    rowPercentageGain: {
      fontFamily: 'Poppins_500Medium',
      marginLeft:0,
      paddingTop:0,
      
      paddingRight:0,
      color: 'green',
      
      fontSize:12,
      fontWeight:'bold',
  
    },
    rowPercentageLoss: {
      fontFamily: 'Poppins_500Medium',
      marginLeft:0,
      paddingTop:0,
      
      paddingRight:0,
      color: 'red',
      
      fontSize:12,
      fontWeight:'bold',
  
    },
    swipeStyle: {
      justifyContent:'center',
      alignItems:'center',
      marginVertical:5,
      marginLeft:-10,
      marginRight:12,
      width:'20%',
      height:63,
      backgroundColor: '#3F4ED1',
      borderRadius:10,

    },
    hideIcon: {
      fontFamily: 'Poppins_500Medium',
      fontSize:20,
      color:'#fff'
    },
    modalBackground: {
      width:'100%',
      height:'100%',
      backgroundColor:'rgba(0,0,0,0.5)',
      justifyContent:'center',
      alignItems:'center'
    },
    modalContainer: {
      width:'95%',
      height:420,
      backgroundColor:'#f6f6f6',
      borderRadius:10,
      justifyContent:'center'
    },
    modalHeader: {
      color:'#ffffff',
      alignSelf:'center',
      fontFamily:'Poppins_500Medium',
      fontSize:20,
      marginTop:15,
    },
    modalRow: {
      flexDirection:'row',
      width:'95%',
      height:65,
      backgroundColor:'#fff',
      alignSelf:'center',
      marginVertical:3,
      marginRight:5,
      borderRadius:10,
      paddingHorizontal:10,
    },
    modalRowSection: {
      width:'40%',
      alignItems:'center',
      justifyContent:'center'
    },
    modalRowLabels:{
      color:'#9c9c9c',
      fontSize:12,
      fontFamily:'Poppins_500Medium',
    },
    modalRowName: {
      color:'#ffffff',
      fontSize:13,
      fontFamily:'Poppins_500Medium',
    },
    modalIgnoreButton: {
      backgroundColor:'red',
      paddingHorizontal:20,
      paddingVertical:3,
      marginVertical:5,
      borderRadius:5
    },
    modalIncludeButton: {
      backgroundColor:'green',
      paddingHorizontal:20,
      paddingVertical:3,
      marginVertical:5,
      borderRadius:5
    },
    modalButtonText: {
      color:'#fff',
      fontSize:15,
      fontFamily:'Poppins_500Medium',
    },
    modalCloseContainer: {
      width:'100%',
      alignItems:'center',
      paddingVertical:5,
    },
    modalCloseButton: {
      backgroundColor:'#3F4Ed1',
      paddingVertical:5,
      marginVertical:5,
      paddingHorizontal:70,
      borderRadius:10
    },
    modalCloseButtonText: {
      color:'white',
      fontFamily:'Poppins_500Medium',
      fontSize:20
    },
    selectionText: {
      fontFamily: 'Poppins_500Medium',
      color:'white',
      fontSize:13,
      
      
  },
  swipeStyle: {
    justifyContent:'center',
    alignItems:'center',
    marginVertical:3,
    marginLeft:-10,
    marginRight:12,
    width:'20%',
    height:65,
    backgroundColor: 'red',
    borderRadius:10,

  },
  swipeLeftIcon: {
    fontSize:15,
      color:'#9c9c9c'
    
  },
  swipeStyleAdd: {
    justifyContent:'center',
    alignItems:'center',
    marginVertical:3,
    marginLeft:-10,
    marginRight:12,
    width:'20%',
    height:65,
    backgroundColor: 'green',
    borderRadius:10,

  },

  //Token Details
  name: {
    color:'black',
    fontFamily: 'Poppins_500Medium',
    fontSize:20,
  },

  percentageChangePositive: {
    color:'green',
    fontFamily: 'Poppins_500Medium',
    fontSize:22, 
  },

  percentageChangeNegative: {
    color:'red',
    fontFamily: 'Poppins_500Medium',
    fontSize:22,
    marginRight:10,
  },

  labels: {
    color:'#9c9c9c',
    fontFamily: 'Poppins_500Medium',
    fontSize:12,
  },
  values: {
    
    color:'#000',
    fontFamily: 'Poppins_500Medium',
    fontSize:15,
  },
  dataContainer: {
    width:'100%',
    flexDirection:'row',
    justifyContent:'space-evenly',
    paddingVertical:10, 
  },

  dataColumn: {
    flexDirection:'column',
    alignItems:'center',
  },

  price: {
    color:'#000',
    fontFamily: 'Poppins_500Medium',
    fontSize:12,
    marginTop:0,
  },
  
  
  rowContainerTokens: {
    flexDirection:'row',
    backgroundColor: '#ffff',
    borderBottomWidth: 2,
    borderColor:'#f6f6f6',
    height: 80,
  },

  imgStyle: {
    width: 30,
    height: 30,
  },

  rangeContainer: {
    flexDirection:'row',
    justifyContent:'space-evenly',
    marginTop:-20,
    marginBottom:10,
    width:'100%',
  },

  inactiveChart: {
    width:40,
    height:40,
    backgroundColor:'#f6f6f6',
    borderRadius:5,
    alignItems:'center',
    justifyContent:'center'
  },

  activeChart: {
    width:40,
    height:40,
    backgroundColor:'#3F4ED1',
    color:'#fff',
    
    borderRadius:5,
    alignItems:'center',
    justifyContent:'center'
  },
  
  activeRange:{
    color:'#fff',
    fontFamily: 'Poppins_500Medium',
    fontSize:15,
  },

  inactiveRange: {
    color:'#000',
    fontFamily: 'Poppins_500Medium',
    fontSize:15,
  },


  });

  export { LightTheme };