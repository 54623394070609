import React, {useState,useEffect} from 'react';
import { StyleSheet,Text, TouchableOpacity,View,Dimensions} from 'react-native';
import { MenuColors } from '../portfolio/portfolioDarkTheme';

var background = '#1F1B24'
var fontColor = '#FFF'
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const Menu = (props) => {
    const [dimensions, setDimensions] = useState({ window, screen });


    useEffect(() => {
        //registerForPushNotificationsAsync("subscribe")
        const subscription = Dimensions.addEventListener(
          "change",
          ({ window, screen }) => {
            setDimensions({ window, screen });
            console.log(dimensions)
            
          }
        );
        
  
  
      }, [])
      return (
    
    <View style={dimensions.window.width > 700 ? MenuStyles.menuContainer : MenuStyles.menuContainerHide}>
        

              <TouchableOpacity style={MenuStyles.content} onPress={() => {props.props.navigation.navigate('Portfolio', {screen: "Portfolio"})}}>
                  <Text style={MenuStyles.values}>Portfolio</Text>
              </TouchableOpacity>

              <TouchableOpacity style={MenuStyles.content} onPress={() => {props.props.navigation.navigate('Discover', {screen: "Markets"})}}>
                  <Text style={MenuStyles.values}>Discover</Text>
              </TouchableOpacity>

              <TouchableOpacity style={MenuStyles.content} onPress={() => {props.props.navigation.navigate('Wallets', {screen: "Your Wallets"})}}>
                  <Text style={MenuStyles.values}>Wallets</Text>
              </TouchableOpacity>

              <TouchableOpacity style={MenuStyles.content} onPress={() => {props.props.navigation.navigate('Settings', {screen: "SettingsTab"})}}>
                  <Text style={MenuStyles.values}>Settings</Text>
              </TouchableOpacity>
        
    </View>

      )
}


const MenuStyles = StyleSheet.create({
    menuContainer: {
      flexDirection:'row',
      backgroundColor: MenuColors.row_background_color,
      alignItems: 'center',
      alignSelf:'center',
      height:'7%',
      justifyContent:'space-evenly',
      width:'95%',
      borderRadius:10,
      borderWidth:2,
      borderColor:MenuColors.border,
      marginVertical:17,

      
    },
    menuContainerHide: {
        display:'none'
  
        
      },
    content: {
        marginHorizontal:'5%'

    },
    values: {
        color:MenuColors.text,
        fontFamily: MenuColors.font,
        fontSize:17,
      },


    });


export default Menu;